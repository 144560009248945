import { useEffect, useState } from "react";

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

function preloadVideo(src: string) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.oncanplaythrough = function () {
      resolve(video);
    };
    video.onerror = function () {
      reject(src);
    };
    video.src = src;
  });
}

function preloadFont(fontUrl: string, fontName: string) {
  return new Promise((resolve, reject) => {
    const font = new FontFace(fontName, `url(${fontUrl})`);
    font
      .load()
      .then((loadedFont) => {
        document.fonts.add(loadedFont);
        resolve(loadedFont);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default function useImagePreloader(imageList: MediaType[]) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      console.log("PRELOAD");

      if (isCancelled) {
        return;
      }

      const imagesPromiseList: Promise<any>[] = [];
      for (const i of imageList) {
        if (i.type === "video") imagesPromiseList.push(preloadVideo(i.src));
        if (i.type === "img") imagesPromiseList.push(preloadImage(i.src));
        if (i.type === "font") imagesPromiseList.push(preloadFont(i.src, i.name ?? ""));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
}
