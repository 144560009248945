import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import { Box, Stack, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import characterOfficer from "../../Assets/Stages/officer.png";
import characterAgent from "../../Assets/Stages/agent.png";
import characterRunner from "../../Assets/Stages/runner.png";
import characterPassenger from "../../Assets/Stages/mom.png";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

import * as appActions from "../../Actions/appActions";

interface StageProps {
  stage: number;
  transition: number;
  setStage: (stage: number) => void;
  setTransition: (stage: number, transition: number) => void;
  t: (key: string) => ReactNode;
  setStory: (value: Partial<StoryType>) => {};
}

type CharacterCardType = {
  key: CharacterType;
  img: string;
};

const characters: CharacterCardType[] = [
  { key: "officer", img: characterOfficer },
  { key: "agent", img: characterAgent },
  { key: "runner", img: characterRunner },
  { key: "passenger", img: characterPassenger },
];

const StageThree = (props: StageProps) => {
  const { t, stage, setStage, transition, setTransition, setStory } = props;

  useEffect(() => {
    if (transition === 0) setTimeout(() => setTransition(stage, transition + 1), 600);
    if (transition === 1) setTimeout(() => setTransition(stage, transition + 1), 600);

    return () => {};
  }, [transition, stage, setStory, setStage, setTransition]);

  const zoom_2 = useSpring({
    opacity: transition === 2 ? 1 : 0,
  });

  const handleOnClickCharacter = (character: CharacterCardType) => {
    setStory({ character: character.key, stage: 1, transition: 0 });
    setTransition(stage, transition + 1);
  };

  return (
    <>
      <animated.div style={{ ...styles.container }}>
        <animated.div style={{ ...styles.subContainer, ...zoom_2 }}>
          <Box sx={styles.boxContainerTexts}>
            <Box sx={styles.title}>{t("stage_three.title")}</Box>
            <Box sx={styles.text}>{t("stage_three.text")}</Box>
          </Box>
          <Stack direction={"row"} sx={styles.containerCharactes}>
            {characters.map((character, index) => (
              <Box sx={styles.character} key={`character_${index}`}>
                <img style={styles.characterImg} alt={""} src={character.img} />
                <Button
                  sx={styles.btn}
                  onClick={() => handleOnClickCharacter(character)}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {t(`characters.${character.key}.position`)}
                </Button>
              </Box>
            ))}
          </Stack>
        </animated.div>
      </animated.div>
    </>
  );
};

const styles = {
  container: {
    overflow: "hidden !important",
    justifyContent: "center",
    alignItems: "center",
    transition: "transform 0.5s ease-in-out",
    display: "flex",
  },
  subContainer: {
    background: "#000000bf",
    width: "80.20833333em",
    padding: "3.6458em 3.9063em",
    justifyContent: "center",
    alignItems: "center",
    height: "45.3333em",
  },
  boxContainerTexts: {
    width: "40.2291em",
    margin: "auto",
  },
  title: {
    fontSize: "4.1666em",
    textAlign: "center",
    fontFamily: "'Tungsten'",
    height: "1.1001em",
    lineHeight: "1.0002em",
  },
  text: {
    fontSize: "1.25em",
    textAlign: "center",
    maxWidth: "100%",
    marginTop: "1.5835em",
    lineHeight: "1.417em",
  },
  containerCharactes: {
    mt: "1.09375em",
    width: "100%",
    flexGrow: 1,
    justifyContent: "space-around",
  },
  character: {
    width: "16.5625em",
  },
  characterImg: {
    width: "100%",
  },
  btn: {
    width: "100%",
    mt: "0.5em",
    pl: "0.5em",
    pr: "0.5em",
    fontSize: "1.06em",
  },
  backdrop: {
    zIndex: -1,
    background: "linear-gradient(to right, #000000, #000000CC)",
    left: 0,
    top: 0,
    position: "absolute" as "absolute",
    height: "100vh",
    width: "100%",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.stage,
    transition: state.appReducer.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStage: appActions.setStage,
  setTransition: appActions.setTransition,
  setStory: appActions.setStory,
};

export default connectWithDispatch(StageThree, mapStateToProps, mapDispatchToProps);
