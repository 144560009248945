import sam from "../Assets/Stages/sam.png";
import brian from "../Assets/Stages/brian.png";
import stacy from "../Assets/Stages/stacy.jpg";
import jared from "../Assets/Stages/jared.jpg";

const DictionaryPictures: { [key: string]: string } = {
  sam: sam,
  brian: brian,
  stacy: stacy,
  jared: jared,
};

export default DictionaryPictures;
