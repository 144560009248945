import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import Message from "../../../../../Commons/Message";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";
import * as appActions from "../../../../../../Actions/appActions";

interface ChatProps {
  screen: number;
  transition: number;
  setStory: (value: Partial<StoryType>) => void;
}

const ChatOne = (props: ChatProps) => {
  const { transition, screen, setStory } = props;

  useEffect(() => {
    if (transition === 4) setTimeout(() => setStory({ transition: transition + 1, animating: true }), 1000);
    if (transition === 5) setTimeout(() => setStory({ transition: transition + 1 }), 1500);
    if (transition === 6) setTimeout(() => setStory({ transition: transition + 1 }), 4000);
    if (transition === 7) setTimeout(() => setStory({ screen: screen + 1, transition: 4 }), 8000);
    return () => {};
  }, [transition, screen, setStory]);

  const propsMsgOne = useSpring({
    opacity: transition >= 4 ? 1 : 0,
    config: { duration: 600 },
  });

  const propsMsgTwo = useSpring({
    marginTop: "1.25em",
    opacity: transition <= 5 ? 0 : 1,
    config: { duration: 600 },
  });

  const propsMsgThree = useSpring({
    marginTop: "1.25em",
    opacity: transition <= 6 ? 0 : 1,
    config: { duration: 600 },
  });

  return (
    <>
      <animated.div style={{ ...propsMsgOne }}>
        <Message name={"Kate"} message={"Hey John"} />
      </animated.div>
      <animated.div style={{ ...propsMsgTwo }}>
        <Message align={"right"} name={"John"} message={"Hi Kate, how are you and how can I help?"} />
      </animated.div>
      <animated.div style={{ ...propsMsgThree }}>
        <Message
          name={"Kate"}
          message={"Wanted to inform you that as a top performer this week you will be receiving a bonus. Great work!"}
        />
      </animated.div>
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(ChatOne, mapStateToProps, mapDispatchToProps);
