import GenericScreen from "../GenericScreen";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { Box } from "@mui/material";

import imgScreen from "../../../../../../Assets/StoryRunner/BRD14.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const Screen = (props: ScreenProps) => {
  const { transition, setStory, screen } = props;

  const handleNextScreen = () => {
    setTimeout(() => setStory({ screen: screen + 1 }), 1000);
  };

  return (
    <>
      <GenericScreen
        showComponent={transition >= 4}
        showContent={true}
        icon={<SentimentSatisfiedAltIcon sx={styles.icon} />}
        title={"Great work"}
        text={"Another successful delivery! Let's check your performance to date. Access your personalised dashboard from the home screen."}
        panelRight={<PhoneScreenOne handleNextScreen={handleNextScreen} />}
      />
    </>
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
    borderRadius: "26px",
  },
};

interface PhoneScreenOneProps {
  handleNextScreen: () => void;
}

const PhoneScreenOne = (props: PhoneScreenOneProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        onClick={props.handleNextScreen}
        sx={{ cursor: "pointer", position: "absolute", top: "19.5vh", width: "17.5vh", height: "18vh", left: "1.8vh" }}
      ></Box>

      <img style={styles.imgScreen} src={imgScreen} alt="" />
    </Box>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
