import { useSpring, animated } from "@react-spring/web";

interface BackdropProps {
  show: boolean;
  from?: string;
  to?: string;
  duration?: number;
}

const Backdrop = (props: BackdropProps) => {
  const { show, from = "transparent", to = "#000000B3", duration = 1800 } = props;
  const bgTransparent = useSpring({
    from: { backgroundColor: from },
    to: { backgroundColor: to },
    config: { duration: duration },
  });

  return <animated.div style={{ ...styles.backdrop, ...(show ? { ...bgTransparent } : { backgroundColor: from }) }}></animated.div>;
};

const styles = {
  backdrop: {
    zIndex: -1,
    backgroundColor: "transparent",
    left: 0,
    top: 0,
    position: "absolute" as "absolute",
    height: "100vh",
    width: "100%",
    transition: "background-color 0.8s ease",
  },
};

export default Backdrop;
