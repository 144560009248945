import { useEffect } from "react";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useSpring, animated } from "@react-spring/web";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

import * as appActions from "../../Actions/appActions";

import Backdrop from "../Commons/Backdrop";

interface StageProps {
  stage: number;
  transition: number;
  setStage: (stage: number) => void;
  setTransition: (stage: number, transition: number) => void;
  t: (key: string) => ReactNode;
}

const StageTwo = (props: StageProps) => {
  const { t, stage, setStage, transition, setTransition } = props;

  useEffect(() => {
    if (transition === 0) setTimeout(() => setTransition(stage, transition + 1), 500);
    if (transition === 1) setTimeout(() => setTransition(stage, transition + 1), 1500);
    if (transition === 2) setTimeout(() => setTransition(stage, transition + 1), 1500);
    if (transition === 3) setTimeout(() => setTransition(stage, transition + 1), 4000);
    if (transition === 4) setTimeout(() => setTransition(stage, transition + 1), 1000);
    if (transition === 5) setTimeout(() => setTransition(stage, transition + 1), 1000);
    if (transition === 7) setTimeout(() => setStage(stage + 1), 800);
    return () => {};
  }, [transition, stage, setTransition, setStage]);

  const propsTransition_1 = useSpring({
    opacity: transition >= 1 && transition <= 6 ? 1 : 0,
  });

  const propsTransition_2 = useSpring({
    opacity: transition >= 2 && transition <= 6 ? 1 : 0,
  });

  const propsTransition_3 = useSpring({
    opacity: transition >= 3 && transition <= 6 ? 1 : 0,
    transform: transition >= 3 && transition <= 6 ? "translateY(0)" : "translateY(20px)",
  });

  const propsTransition_4 = useSpring({
    opacity: transition >= 4 && transition <= 6 ? 1 : 0,
    transform: transition >= 4 && transition <= 6 ? "translateY(0)" : "translateY(20px)",
  });

  const propsTransition_5 = useSpring({
    opacity: transition >= 5 ? 1 : 0,
    transform: transition >= 5 ? "translateY(0)" : "translateY(20px)",
  });

  return (
    <>
      {transition < 3 && <Backdrop duration={400} show={transition >= 1} />}
      {transition >= 3 && <Backdrop duration={400} show={transition > 6} from="#000000B3" to="transparent" />}
      <animated.div style={{ ...styles.container, ...propsTransition_1 }}>
        <animated.div style={{ ...styles.title, ...propsTransition_2 }}>{t("stage_two.title_part_1")}</animated.div>
        <animated.div style={{ ...styles.title, ...propsTransition_2 }}>{t("stage_two.title_part_2")}</animated.div>
        <animated.div style={{ ...styles.text, ...propsTransition_3 }}>{t("stage_two.text_part_1")}</animated.div>
        <animated.div style={{ ...styles.text, ...propsTransition_4 }}>{t("stage_two.text_part_2")}</animated.div>
        <animated.div style={{ ...styles.containerBtn, ...propsTransition_5 }}>
          <Button onClick={() => setTransition(stage, transition + 1)} color="primary" variant="contained" endIcon={<ArrowForwardIcon />}>
            {t("stage_two.btn_continue")}
          </Button>
        </animated.div>
      </animated.div>
    </>
  );
};

const styles = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column" as "column",
    position: "absolute" as "absolute",
  },
  title: {
    textAlign: "center" as "center",
    fontSize: "1.86em",
    fontWeight: "bold",
  },
  text: {
    marginTop: "1.8335em",
    maxWidth: "28.81em",
    textAlign: "center" as "center",
    fontSize: "1.25em",
  },
  containerBtn: {
    textAlign: "center" as "center",
    marginTop: "2.2918em",
  },
  backdrop: {
    zIndex: -1,
    background: "linear-gradient(to right, #000000, #00000066)",
    left: 0,
    top: 0,
    position: "absolute" as "absolute",
    height: "100vh",
    width: "100%",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.stage,
    transition: state.appReducer.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStage: appActions.setStage,
  setTransition: appActions.setTransition,
};

export default connectWithDispatch(StageTwo, mapStateToProps, mapDispatchToProps);
