import { Stack, Box, Tooltip } from "@mui/material";

import Fullscreen from "./Fullscreen";
import BackHome from "./BackHome";
import BackCharacters from "./BackCharacters";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

interface OptionsProps {
  character: CharacterType;
  transition: number;
  animating: boolean;
}

const Options = (props: OptionsProps) => {
  const { character, transition, animating } = props;
  return (
    <Stack direction={"row"} sx={styles.container}>
      {character !== null && transition !== 0 && animating && (
        <>
          <Tooltip title="please wait until the animation ends" followCursor>
            <Box sx={styles.optionDisabled}>
              <BackHome disabled={true} />
            </Box>
          </Tooltip>
          <Tooltip title="please wait until the animation ends" followCursor>
            <Box sx={styles.optionDisabled}>
              <BackCharacters disabled={true} />
            </Box>
          </Tooltip>
        </>
      )}
      {character !== null && transition !== 0 && !animating && (
        <>
          <Box sx={styles.option} className={"option_hover"}>
            <BackHome />
          </Box>
          <Box sx={styles.option} className={"option_hover"}>
            <BackCharacters />
          </Box>
        </>
      )}
      <Box sx={styles.option} className={"option_hover"}>
        <Fullscreen />
      </Box>
    </Stack>
  );
};

const styles = {
  container: {
    position: "absolute",
    zIndex: 1001,
    right: 0,
    top: 0,
  },
  option: {
    margin: "0.5em",
    background: "#00000080",
    border: "1px solid white",
    cursor: "pointer",
  },
  optionDisabled: {
    margin: "0.5em",
    background: "#595959",
    border: "1px solid #afafaf",
    cursor: "default",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    character: state.appReducer.story.character,
    transition: state.appReducer.story.transition,
    animating: state.appReducer.story.animating,
  };
};

export default connectWithDispatch(Options, mapStateToProps);
