import { useEffect } from "react";
import { Box } from "@mui/material";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

import imageCalling from "../../../../../../Assets/StoryAgent/call_brian.png";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const Screen = (props: ScreenProps) => {
  const { t, screen, setStory } = props;

  useEffect(() => {
    if (screen === 8) setTimeout(() => setStory({ screen: screen + 2 }), 4500);

    return () => {};
  }, [screen, setStory]);

  return (
    <ScreenContainer>
      <Box sx={styles.topBox}>
        <Box sx={styles.title}>{t("commons.title")}</Box>
        <Box sx={styles.boxText}>
          <Box sx={styles.text_2}>{t("agent.stage_three.screen_8.text_2")}</Box>
        </Box>
      </Box>
      <Box sx={styles.people}>
        <img src={imageCalling} alt="" style={styles.imageCalling} />
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  topBox: {
    textAlign: "center",
    width: "100%",
  },
  title: {
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  boxText: {
    marginTop: "0.41666667em",
  },
  text_2: {
    fontSize: "1.25em",
  },
  people: {
    objectFit: "contain" as "contain",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "2.60416667em",
  },
  imageCalling: {
    width: "19.352vh",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
