import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
  theme: Theme;
}

const Screen = (props: ScreenProps) => {
  const { t, screen, setStory } = props;
  const { main } = props.theme.palette.error;

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  return (
    <ScreenContainer>
      <WarningAmberIcon sx={{ ...styles.icon }} />
      <Box sx={styles.title}>{t("commons.title")}</Box>
      <Box sx={styles.text_2}>
        {t("agent.stage_three.screen_3.text_2_part_1")}
        <span style={{ ...styles.span, color: main }}>{t("agent.stage_three.screen_3.text_2_part_2")}</span>
        {t("agent.stage_three.screen_3.text_2_part_3")}
        <span style={{ ...styles.span, color: main }}>{t("agent.stage_three.screen_3.text_2_part_4")}</span>
      </Box>
      <Box sx={styles.text_3}>{t("agent.stage_three.screen_3.text_3")}</Box>
      <Button sx={styles.btn} onClick={handleNextScreen} variant="contained" endIcon={<ArrowForwardIcon />}>
        {t("agent.stage_three.screen_3.btn_action")}
      </Button>
    </ScreenContainer>
  );
};

const styles = {
  btn: {
    marginTop: "2em",
  },
  icon: {
    fontSize: "4vw",
  },
  title: {
    fontFamily: "SpaceMono",
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  text_2: {
    fontSize: "1.25em",
    marginTop: "1em",
  },
  text_3: {
    fontSize: "1.25em",
  },
  span: {
    fontWeight: "bold",
    fontSize: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
