import GenericScreen from "../GenericScreen";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box } from "@mui/material";

import imgScreen from "../../../../../../Assets/StoryRunner/BRD16.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

interface ScreenProps {
  transition: number;
}

const Screen = (props: ScreenProps) => {
  const { transition } = props;

  return (
    <>
      <GenericScreen
        showComponent={transition >= 4}
        showContent={true}
        icon={<TrendingUpIcon sx={styles.icon} />}
        title={"Performance overview"}
        text={"John that is impressive. Let’s make a note on our last delivery and stand by for a task."}
        panelRight={<PhoneScreenOne />}
      />
    </>
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
    borderRadius: "26px",
  },
};

interface PhoneScreenOneProps {}

const PhoneScreenOne = (props: PhoneScreenOneProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <img style={styles.imgScreen} src={imgScreen} alt="" />
    </Box>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

export default connectWithDispatch(Screen, mapStateToProps);
