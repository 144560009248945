import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSpring, animated } from "@react-spring/web";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

import faceSecurity from "../../../../../../Assets/StoryOfficer/Layer_1.png";

import Ball from "./Ball";

interface ScreenProps {
  screen: number;
  transition: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const Screen = (props: ScreenProps) => {
  const { t, transition, setStory, screen } = props;

  useEffect(() => {
    if (screen === 1 && transition >= 2 && transition <= 7)
      setTimeout(() => setStory({ transition: transition + 1, animating: true }), 200);
    if (screen === 1 && transition === 8) setTimeout(() => setStory({ transition: transition + 1, animating: false }), 1000);

    return () => {};
  }, [transition, screen, setStory]);

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  const propsOpacityBtn = useSpring({
    opacity: transition >= 9 ? 1 : 0,
    config: { duration: 1200 },
  });

  return (
    <ScreenContainer>
      <Box sx={{ position: "relative", width: "17vw", margin: "auto" }}>
        <Ball show={transition > 3} granted={transition >= 8} left={"22%"} top={"17%"} />
        <Ball show={transition > 4} granted={transition >= 8} left={"22.5%"} top={"41.5%"} />
        <Ball show={transition > 5} granted={transition >= 8} left={"72.5%"} top={"41.5%"} />
        <Ball show={transition > 6} granted={transition >= 8} left={"38.5%"} top={"56.5%"} />
        <Ball show={transition > 7} granted={transition >= 8} left={"66.5%"} top={"75.5%"} />
        <img src={faceSecurity} alt="" style={styles.faceSecurity} />
      </Box>
      <animated.div style={propsOpacityBtn}>
        <Button sx={styles.btn} onClick={handleNextScreen} variant="contained" endIcon={<ArrowForwardIcon />}>
          {t("officer.stage_two.screen_1.btn")}
        </Button>
      </animated.div>
    </ScreenContainer>
  );
};

const styles = {
  btn: {
    marginTop: "2.1875em",
  },
  faceSecurity: {
    maxWidth: "16.56em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
