import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Box, Stack } from "@mui/material";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

import imageCalling from "../../../../../../Assets/StoryAgent/call_sam.png";

import Message from "../../../../../Commons/Message";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  theme: Theme;
}

const Screen = (props: ScreenProps) => {
  const { transition, screen, setStory } = props;
  const { secondary } = props.theme.palette.text;

  useEffect(() => {
    if (screen === 11 && transition === 2) setTimeout(() => setStory({ transition: transition + 1 }), 500);
    if (screen === 11 && transition === 3) setTimeout(() => setStory({ transition: transition + 1 }), 4000);
    if (screen === 11 && transition === 4) setTimeout(() => setStory({ transition: transition + 1 }), 8000);

    if (screen === 11 && transition === 5) setTimeout(() => setStory({ screen: screen + 1, transition: 2 }), 4000);

    return () => {};
  }, [transition, screen, setStory]);

  const propsMsgOne = useSpring({
    opacity: screen === 11 && transition > 2 ? 1 : 0,
    config: { duration: 600 },
  });

  const propsMsgTwo = useSpring({
    marginTop: "1.25em",
    opacity: screen === 11 && transition > 3 ? 1 : 0,
    config: { duration: 600 },
  });

  const propsBtn = useSpring({
    opacity: screen === 11 && transition > 4 ? 1 : 0,
    config: { duration: 600 },
  });

  const propsChat = useSpring({
    opacity: screen === 11 && transition < 6 ? 1 : 0,
    config: { duration: 600 },
  });

  return (
    <ScreenContainer height={"auto"}>
      <Box sx={{ width: "53.38541667em" }}>
        <Stack direction={"row"} sx={{ justifyContent: "center", alignItems: "flex-start", marginTop: "2.60416667em" }}>
          <animated.div style={{ ...styles.people }}>
            <img src={imageCalling} alt="" style={styles.imageCalling} />
          </animated.div>
          <animated.div style={{ marginLeft: "7.08333333em", flex: 1, ...propsChat }}>
            <animated.div style={{ ...propsMsgOne }}>
              <Message name={"Sam"} message={"Hey Mel, what can I do for you?"} />
            </animated.div>
            <animated.div style={{ ...propsMsgTwo }}>
              <Message
                name={"Melissa"}
                message={
                  <Box sx={{ fontSize: "1em" }}>
                    Hey Sam need a quick favour. It looks like we are missing two ULDs for our upcoming flight
                    <span style={{ ...styles.span, color: secondary }}>{` JI895`}</span>.
                  </Box>
                }
                message2={
                  <Box sx={{ fontSize: "1em" }}>
                    According to our latest information the last know location for both are{" "}
                    <span style={{ ...styles.span, color: secondary }}>{` CG02`}</span>. Sending across the ULD details to you right now.
                  </Box>
                }
              />
            </animated.div>
            <animated.div style={{ ...propsBtn }}>
              <Message name={"Sam"} message={"Got it! Give me a moment."} />
            </animated.div>
          </animated.div>
        </Stack>
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  topBox: {
    textAlign: "center" as "center",
    width: "100%",
  },
  text_1: {
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  boxText: {
    marginTop: "0.41666667em",
  },
  text_2: {
    fontSize: "1.25em",
  },
  people: {
    objectFit: "contain" as "contain",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "2.60416667em",
  },
  imageCalling: {
    width: "19.352vh",
  },
  span: {
    fontSize: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
