import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  theme: Theme;
  setScreenPopUp: (value: ScreenPopUpType | null) => void;
  t: (key: string) => ReactNode;
}

const Screen12 = (props: ScreenProps) => {
  const { t, setScreenPopUp } = props;
  const { secondary } = props.theme.palette.text;

  const handleExit = () => setScreenPopUp("storyEnded");

  return (
    <ScreenContainer>
      <Box sx={{ width: "39.32291667em", margin: "auto" }}>
        <TaskAltIcon sx={{ ...styles.icon, color: secondary }} />
        <Box sx={styles.title}>{t("commons.title")}</Box>
        <Box sx={styles.text_2}>Great work Melissa, Looks like the Control Manager was able to source one from a nearby airport.</Box>
        <Box sx={{ ...styles.text_3, color: secondary }}>All upcoming ULDs requirements met</Box>
        <Button sx={styles.btn} onClick={handleExit} variant="contained" endIcon={<ArrowForwardIcon />}>
          Exit
        </Button>
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  btn: {
    marginTop: "2em",
  },
  icon: {
    fontSize: "4vw",
  },
  title: {
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  text_2: {
    fontSize: "1.25em",
    marginTop: "1em",
  },
  text_3: {
    fontSize: "1.25em",
  },
  span: {
    fontWeight: "bold",
    fontSize: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setScreenPopUp: appActions.setScreenPopUp,
};

export default connectWithDispatch(Screen12, mapStateToProps, mapDispatchToProps);
