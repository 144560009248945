import { Box } from "@mui/material";

import connectWithDispatch from "../../../Hooks/connectWithDispatch";

import * as appActions from "../../../Actions/appActions";

interface PhoneScreenOneProps {
  handleNextScreen?: () => void;
  imgScreen: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  height?: string;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
}

const GenericPhoneScreen = (props: PhoneScreenOneProps) => {
  const { handleNextScreen, screen, setStory, imgScreen, top, bottom = false, left = false, right, width, height } = props;

  const handleNextScreenGeneric = () => {
    if (handleNextScreen) handleNextScreen();
    else setTimeout(() => setStory({ screen: screen + 1 }), 1000);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {
        <Box
          onClick={handleNextScreenGeneric}
          sx={{
            cursor: "pointer",
            position: "absolute",
            ...(top ? { top } : {}),
            ...(bottom ? { bottom } : {}),
            ...(left ? { left } : {}),
            ...(right ? { right } : {}),
            ...(width ? { width } : {}),
            ...(height ? { height } : {}),
          }}
        ></Box>
      }
      <img style={styles.imgScreen} src={imgScreen} alt="" />
    </Box>
  );
};

const styles = {
  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
    borderRadius: "26px",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(GenericPhoneScreen, mapStateToProps, mapDispatchToProps);
