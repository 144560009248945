import { Box, Button, Stack } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import * as appActions from "../../Actions/appActions";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

interface ExitScreenProps {
  screenPopUp: "backHome" | "backCharacters" | "storyEnded";
  setScreenPopUp: (value: ScreenPopUpType | null) => void;
  setStage: (value: number) => void;
  setStory: (value: Partial<StoryType>) => void;
  setTransition: (stage: number, transition: number) => void;
}

const ExitScreen = (props: ExitScreenProps) => {
  const { screenPopUp, setScreenPopUp, setStage, setStory, setTransition } = props;

  const handleOk = () => {
    setStory({ character: null, stage: 1, transition: 0, screen: 0 });
    if (screenPopUp === "backHome") {
      setStage(1);
    }

    if (screenPopUp === "backCharacters") {
      setStage(3);
      setTransition(1, 2);
    }

    setScreenPopUp(null);
  };

  const handleCancel = () => setScreenPopUp(null);

  return (
    <>
      {screenPopUp !== null && screenPopUp !== "storyEnded" && (
        <Box sx={styles.screen}>
          <Box sx={styles.container}>
            {screenPopUp === "backHome" && (
              <>
                <HomeIcon sx={{ fontSize: "3.125em" }} />
                <Box sx={styles.text_1}>Are you sure you want to quit from the experience?</Box>
                <Box sx={styles.text_2}>You will lose the progress from the storyline and go back to home page.</Box>
              </>
            )}

            {screenPopUp === "backCharacters" && (
              <>
                <PersonIcon sx={{ fontSize: "3.125em" }} />
                <Box sx={styles.text_1}>Are you sure you want to quit from the experience?</Box>
                <Box sx={styles.text_2}>You will lose the progress from the storyline and Go back to character selection.</Box>
              </>
            )}
            <Stack direction={"row"} sx={{ marginTop: "1.30208333em" }}>
              <Box sx={styles.containerBtn}>
                <Button onClick={handleCancel} variant="contained" endIcon={<ArrowForwardIcon />}>
                  No, cancel the action
                </Button>
              </Box>
              <Box sx={styles.containerBtn}>
                <Button onClick={handleOk} variant="contained" endIcon={<ArrowForwardIcon />}>
                  Yes, I want to quit
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

const styles = {
  screen: {
    position: "absolute",
    backgroundColor: "#000000E0",
    zIndex: 1000,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "44.6875em",
  },
  text_1: {
    marginTop: "1em",
    fontSize: "1.45833333em",
    fontWeight: "bold",
    textAlign: "center",
  },
  text_2: {
    marginTop: "1em",
    fontSize: "1.25em",
    textAlign: "center",
  },
  containerBtn: {
    margin: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screenPopUp: state.appReducer.screenPopUp,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setScreenPopUp: appActions.setScreenPopUp,
  setStage: appActions.setStage,
  setStory: appActions.setStory,
  setTransition: appActions.setTransition,
};

export default connectWithDispatch(ExitScreen, mapStateToProps, mapDispatchToProps);
