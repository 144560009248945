import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
  theme: Theme;
}

const Screen12 = (props: ScreenProps) => {
  const { t, screen, setStory } = props;
  const { main } = props.theme.palette.error;

  const handleNextScreen = () => setStory({ screen: screen + 1 });

  return (
    <ScreenContainer>
      <Box sx={{ width: "39.6875em", margin: "auto" }}>
        <WarningAmberIcon sx={{ ...styles.icon }} />
        <Box sx={styles.title}>{t("commons.title")}</Box>
        <Box sx={styles.text_2}>
          Good job Melissa! Looks like we have one ULD on route to <span style={{ fontWeight: "bold" }}>JI1895</span>. Still{" "}
          <span style={{ ...styles.span, color: main }}>one unknown ULD remaining</span>.
        </Box>
        <Box sx={styles.text_3}>Let’s check the ULDs for breakdown.</Box>
        <Button sx={styles.btn} onClick={handleNextScreen} variant="contained" endIcon={<ArrowForwardIcon />}>
          View on dashboard
        </Button>
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  btn: {
    marginTop: "2em",
  },
  icon: {
    fontSize: "4vw",
  },
  title: {
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  text_2: {
    fontSize: "1.25em",
    marginTop: "1em",
  },
  text_3: {
    fontSize: "1.25em",
  },
  span: {
    fontWeight: "bold",
    fontSize: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen12, mapStateToProps, mapDispatchToProps);
