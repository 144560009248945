import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import { Box } from "@mui/material";

import imgCharacter from "../../../../Assets/StoryOfficer/Security_1.png";

import connectWithDispatch from "../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../Actions/appActions";

import CardPresentation from "../../../Commons/CardPresentation";

interface StageProps {
  transition: number;
  stage: number;
  setStory: (value: Partial<StoryType>) => void;
}

const StageOne = (props: StageProps) => {
  const { transition, stage, setStory } = props;

  useEffect(() => {
    if (transition === 1) setTimeout(() => setStory({ transition: transition + 1 }), 400);
    if (transition === 3) setTimeout(() => setStory({ stage: stage + 1, transition: 1 }), 1200);

    return () => {};
  }, [transition, stage, setStory]);

  const propsOpacity = useSpring({
    opacity: transition >= 2 && transition < 3 ? 1 : 0,
    config: { duration: 500 },
  });

  return (
    <Box style={{ ...styles.container }}>
      <animated.div style={propsOpacity}>
        <CardPresentation />
        <img src={imgCharacter} alt="" style={styles.character} />
      </animated.div>
    </Box>
  );
};

const styles = {
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  character: {
    position: "absolute" as "absolute",
    right: "12.5em",
    bottom: "0",
    zIndex: 10,
    width: "61.575vh",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.story.stage,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(StageOne, mapStateToProps, mapDispatchToProps);
