import { Box, Button } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import Background from "./Commons/Background";

import StageOne from "./Stages/StageOne";
import StageTwo from "./Stages/StageTwo";
import StageThree from "./Stages/StageThree";

import CharacterOfficer from "./Stories/CharacterOfficer";
import CharacterAgent from "./Stories/CharacterAgent";
import CharacterRunner from "./Stories/CharacterRunner";
import CharacterPassenger from "./Stories/CharacterPassenger";

import connectWithDispatch from "../Hooks/connectWithDispatch";

import * as appActions from "../Actions/appActions";

interface MainViewProps {
  stage: number;
  transition: number;
  story: StoryType;
  t: (key: string) => ReactNode;
  setStory: (value: Partial<StoryType>) => void;
}

const MainView = (props: MainViewProps) => {
  const { t, stage, story, setStory } = props;

  const propsBtnSkip = useSpring({
    zIndex: 10,
    display: story.stage === 1 && story.character !== null && story.transition === 0 ? "block" : "none",
  });

  const handleSkip = () => {
    setStory({ transition: 1 });
  };

  return (
    <Box sx={styles.MainViewContainer}>
      <animated.div style={{ ...styles.btn_skip, ...propsBtnSkip }}>
        <Button
          onClick={() =>
            setTimeout(() => {
              handleSkip();
            }, 100)
          }
          variant="contained"
          endIcon={<SkipNextIcon />}
        >
          {t("stage_one.btn_skip")}
        </Button>
      </animated.div>
      <Background />

      {/* <Box sx={{ color: "RED", position: "absolute", left: 0, top: 0 }}>
        {stage}
        {" | "}
        {transition}
        {" | "}
        {JSON.stringify(story)}
      </Box> */}

      {stage === 1 && <StageOne />}
      {stage === 2 && <StageTwo />}
      {stage === 3 && story.character === null && story.transition === 0 && <StageThree />}

      {stage === 3 && story.transition > 0 && story.character === "officer" && <CharacterOfficer />}
      {stage === 3 && story.transition > 0 && story.character === "agent" && <CharacterAgent />}
      {stage === 3 && story.transition > 0 && story.character === "runner" && <CharacterRunner />}
      {stage === 3 && story.transition > 0 && story.character === "passenger" && <CharacterPassenger />}
    </Box>
  );
};

const styles = {
  MainViewContainer: {
    width: "100%",
    height: "100VH",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btn_skip: {
    position: "absolute" as "absolute",
    right: "1em",
    bottom: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.stage,
    transition: state.appReducer.transition,
    story: state.appReducer.story,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(MainView, mapStateToProps, mapDispatchToProps);
