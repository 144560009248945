import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ScreenContainer from "./ScreenContainer";

import picture_profile from "../../../../../../Assets/StoryAgent/Rectangle_1805.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const Screen = (props: ScreenProps) => {
  const { t, screen, setStory } = props;

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  return (
    <ScreenContainer>
      <img src={picture_profile} style={styles.picture_profile} alt="" />
      <Box sx={styles.text_1}>{t("agent.stage_three.screen_2.text_1")}</Box>
      <Box sx={styles.text_2}> {t("agent.stage_three.screen_2.text_2")}</Box>
      <Box sx={styles.text_3}> {t("agent.stage_three.screen_2.text_3")}</Box>
      <Box sx={styles.btn}>
        <Button onClick={handleNextScreen} variant="contained" endIcon={<ArrowForwardIcon />}>
          {t("agent.stage_three.screen_2.btn_option_1")}
        </Button>
        <Button sx={{ marginLeft: " 2.6041em" }} onClick={handleNextScreen} variant="contained" endIcon={<ArrowForwardIcon />}>
          {t("agent.stage_three.screen_2.btn_option_2")}
        </Button>
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  btn: {
    marginTop: "2.1875em",
  },
  text_1: {
    fontWeight: "bold",
    fontSize: "1.25em",
    lineHeight: "1.5835em",
    marginTop: "0.634em",
  },
  text_2: {
    margin: "auto",
    marginTop: "1em",
    fontSize: "1.25em",
  },
  text_3: {
    margin: "auto",
    fontSize: "1.25em",
  },
  picture_profile: {
    width: "4.74em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
