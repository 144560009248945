import GenericScreen from "../../../GenericScreen";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import GenericPhoneScreen from "../../../GenericPhoneScreen";
import imgScreen from "../../../../../../Assets/StoryPassenger/PHONE_13.jpg";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
}

const Screen = (props: ScreenProps) => {
  const { transition, screen, setStory } = props;

  const handleNextScreen = () => {
    setTimeout(() => setStory({ screen: screen + 1, animating: true }), 1000);
  };

  return (
    <GenericScreen
      showComponent={transition >= 4}
      showContent={true}
      icon={<PersonOutlineIcon sx={styles.icon} />}
      title={"Behavioural analysis"}
      text={"Spot on! Looks like you don’t need to make any changes to your meals. "}
      panelRight={
        <GenericPhoneScreen
          handleNextScreen={handleNextScreen}
          imgScreen={imgScreen}
          bottom={"25.4vh"}
          width={"33.3vh"}
          height={"5.2vh"}
          left={"3.6vh"}
        />
      }
    />
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
