import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

import * as appActions from "../../Actions/appActions";

interface CardPresentationProps {
  character: CharacterType;
  theme: Theme;
  transition: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const CardPresentation = (props: CardPresentationProps) => {
  const { t, setStory, transition, character } = props;
  const { secondary } = props.theme.palette.text;

  const handleOnClick = () => {
    setStory({ transition: transition + 1 });
  };

  const widths = { officer: "47em", agent: "46.875em", runner: "46.875em", passenger: "46.875em" };

  return (
    <>
      {character !== null && (
        <>
          <Box style={{ ...styles.containerBox, width: widths[character] }}>
            <Box sx={styles.title}>
              {`${t(`characters.${character}.name`)}: `}
              <span style={{ ...styles.title_part_2, color: secondary }}>{t(`characters.${character}.short_name_position`)}</span>
            </Box>
            <Box sx={styles.text}>{t(`characters.${character}.description`)}</Box>
            <Button sx={styles.btn} onClick={handleOnClick} variant="contained" endIcon={<ArrowForwardIcon />}>
              {t(`commons.btn_proceed`)}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

const styles = {
  containerBox: {
    background: "#000000bf",
    zIndex: 10,
    position: "relative" as "relative",
    padding: "4.75em 11em 4.75em 4.75em",
    borderRadius: "10px",
  },
  title: {
    fontFamily: "Tungsten",
    textAlign: "left" as "left",
    fontSize: "4.16666667em",
    textTransform: "uppercase",
    lineHeight: "1em",
    textShadow: "2px 4px 5px black",
  },
  title_part_2: {
    textAlign: "left" as "left",
    fontSize: "1em",
  },
  text: {
    marginTop: "1em",
    fontFamily: "SpaceMono",
    fontSize: "1.25em",
    textAlign: "left",
    maxWidth: "100%",
  },
  btn: {
    marginTop: "1.6em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    character: state.appReducer.story.character,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(CardPresentation, mapStateToProps, mapDispatchToProps);
