import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface MessageProps {
  name: string;
  message: string | ReactNode;
  message2?: string | ReactNode;
  align?: "left" | "right";
}

const Message = (props: MessageProps) => {
  const { name, message, message2 } = props;
  const theme = useTheme();
  const primaryColor = theme.palette.primary.dark;
  const align = props?.align ?? "left";

  return (
    <Box>
      <Box sx={{ ...styles.name, color: primaryColor, textAlign: align }}>{name}</Box>
      <Box sx={{ ...styles.text, textAlign: align }}>{message}</Box>
      {message2 && <Box sx={{ ...styles.text, textAlign: align, marginTop: "1.25em" }}>{message2}</Box>}
    </Box>
  );
};

const styles = {
  name: {
    fontSize: "1.25em",
    textAlign: "left",
    lineHeight: "1.25em",
  },
  text: {
    fontSize: "1.25em",
    textAlign: "left",
    maxWidth: "100%",
  },
};

export default Message;
