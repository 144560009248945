import GenericScreen from "../../../GenericScreen";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import GenericPhoneScreen from "../../../GenericPhoneScreen";
import imgScreen from "../../../../../../Assets/StoryPassenger/PHONE_12.jpg";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

interface ScreenProps {
  transition: number;
}

const Screen = (props: ScreenProps) => {
  const { transition } = props;

  return (
    <GenericScreen
      showComponent={transition >= 4}
      showContent={true}
      icon={<DoneAllIcon sx={styles.icon} />}
      title={"Upgrade successful"}
      text={"This should make mum’s journey a lot more comfortable."}
      panelRight={<GenericPhoneScreen imgScreen={imgScreen} top={"33.6vh"} width={"33.3vh"} height={"5.1vh"} left={"3.6vh"} />}
    />
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

export default connectWithDispatch(Screen, mapStateToProps);
