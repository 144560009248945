import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import bg from "../../../../../Assets/StoryRunner/phone.png";

import connectWithDispatch from "../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../Actions/appActions";

import Screen15 from "./phone/Screen15";
import Screen16 from "./phone/Screen16";
import Screen17 from "./phone/Screen17";
import Screen18 from "./phone/Screen18";
import Screen20 from "./phone/Screen20";

import ScreenAnimator from "../../../../Commons/ScreenAnimator";

import Backdrop from "../../../../Commons/Backdrop";

interface StageProps {
  stage: number;
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const StageThree = (props: StageProps) => {
  const { stage, transition, setStory, screen } = props;

  useEffect(() => {
    if (transition === 1 && screen === 0) setTimeout(() => setStory({ transition: transition + 1, screen: 1 }), 100);
    if (transition === 2) setTimeout(() => setStory({ transition: transition + 1 }), 1000);
    if (transition === 3) setTimeout(() => setStory({ transition: transition + 1, animating: false }), 1000);

    return () => {};
  }, [transition, screen, setStory]);

  useEffect(() => {
    return () => {};
  }, [screen, stage, setStory]);

  const propsOpacity = useSpring({
    zIndex: screen === 14 ? 100 : -1,
    background: "#000",
    position: "absolute" as "absolute",
    width: "100vw",
    height: "100vh",
    opacity: screen === 14 ? 1 : 0,
    config: { duration: 500 },
  });

  return (
    <>
      {transition < 4 && <Backdrop show={transition > 0} duration={600} from={"#000000"} to={"transparent"} />}
      <animated.div style={{ background: screen <= 14 ? `url(${bg})` : "none", ...styles.bgTablet }}>
        <ScreenAnimator
          width={"124.07vh"}
          currentScreen={screen - 1}
          screens={[<Screen15 />, <Screen16 />, <Screen17 />, <Screen18 />, <Screen20 />]}
        />
        <animated.div style={propsOpacity}></animated.div>
      </animated.div>
    </>
  );
};

const styles = {
  bgTablet: {
    backgroundSize: "contain",
    backgroundPosition: "center center",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
  },

  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.story.stage,
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(StageThree, mapStateToProps, mapDispatchToProps);
