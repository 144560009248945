import { Box, Stack } from "@mui/material";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

import CardEmployee from "../../../../../Commons/CardEmployee";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
  theme: Theme;
}

const Screen6 = (props: ScreenProps) => {
  const { t, screen, setStory } = props;

  const handleStacy = () => setStory({ screen: screen + 2, transition: 2, animating: true });
  const handleJared = () => setStory({ screen: screen + 1, transition: 2, animating: true });

  return (
    <ScreenContainer>
      <Box sx={styles.topBox}>
        <Box sx={styles.title}>{t("commons.title")}</Box>
        <Box sx={styles.text_2}>
          {t("officer.stage_two.screen_6.text_1_part_1")}
          <span style={{ color: "#F16C51", fontSize: "1em" }}>{t("officer.stage_two.screen_6.text_1_part_2")}</span>
          {t("officer.stage_two.screen_6.text_1_part_3")}
        </Box>
      </Box>
      <Stack sx={styles.people} direction={"row"}>
        <Box onClick={handleStacy}>
          <CardEmployee keyEmployee={"stacy"} />
        </Box>
        <Box onClick={handleJared}>
          <CardEmployee keyEmployee={"jared"} />
        </Box>
      </Stack>
    </ScreenContainer>
  );
};

const styles = {
  topBox: {
    textAlign: "center",
    width: "100%",
  },
  title: {
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  text_2: {
    width: "42em",
    fontSize: "1.25em",
    margin: "auto",
    marginTop: "1em",
  },
  people: {
    objectFit: "contain" as "contain",
    height: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    display: "flex",
    margin: "auto",
    mt: "4.0625em",
    width: "48.959em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen6, mapStateToProps, mapDispatchToProps);
