import { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import characterRunner from "../../../../Assets/StoryAgent/Dave.png";
import characterAgent from "../../../../Assets/StoryAgent/melissa_left.png";

import Message from "../../../Commons/Message";
import Notification from "../../../Commons/Notification";

import Backdrop from "../../../Commons/Backdrop";

import connectWithDispatch from "../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../Actions/appActions";

interface StageProps {
  stage: number;
  transition: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const StageTwo = (props: StageProps) => {
  const { t, stage, transition, setStory } = props;
  const [answerChoosed, setAnswerChoosed] = useState<string>("");

  const viewportHeight = window.innerHeight;

  const move165 = viewportHeight * 0.1776;
  const move295 = viewportHeight * 0.3175;
  const move432 = viewportHeight * 0.4671;
  const move608 = viewportHeight * 0.6544;

  useEffect(() => {
    if (transition >= 1 && transition < 3) setTimeout(() => setStory({ transition: transition + 1, animating: true }), 650);
    if (transition === 3) setTimeout(() => setStory({ transition: transition + 1, animating: false }), 4000);
    if (transition === 5) setTimeout(() => setStory({ transition: transition + 1 }), 1500);
    if (transition === 6) setTimeout(() => setStory({ transition: transition + 1 }), 5000);
    if (transition === 7) setTimeout(() => setStory({ transition: transition + 1 }), 3000);
    if (transition === 8) setTimeout(() => setStory({ transition: transition + 1 }), 2500);
    if (transition === 9) setTimeout(() => setStory({ transition: transition + 1, animating: false }), 2000);
    if (transition === 11) setTimeout(() => setStory({ stage: stage + 1, transition: 1, screen: 0 }), 1200);

    return () => {};
  }, [stage, transition, setStory]);

  const getTranslateYChatBox = () => {
    switch (transition) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return `translateY(0px)`;
      case 6:
        return `translateY(-${move165}px)`;
      case 7:
        return `translateY(-${move295}px)`;
      case 8:
        return `translateY(-${move432}px)`;
      case 9:
        return `translateY(-${move608}px)`;
    }
  };

  const propsOpacityContainer = useSpring({
    opacity: transition >= 2 && transition < 11 ? 1 : 0,
    config: { duration: transition === 11 ? 300 : 800 },
  });

  const propsCharacterRunner = useSpring({
    zIndex: -1,
    position: "absolute" as "absolute",
    left: transition >= 11 ? "-150%" : "-79%",
    width: "56.853vh",
    bottom: "-1em",
    config: { duration: 600 },
  });

  const propsCharacterAgent = useSpring({
    zIndex: -1,
    position: "absolute" as "absolute",
    right: transition >= 11 ? "-150%" : "-80%",
    width: "71.112vh",
    bottom: "-1em",
    config: { duration: 600 },
  });

  const propsChatBox = useSpring({
    transform: getTranslateYChatBox(),
    config: { duration: 300 },
  });

  const propsFirstMsg = useSpring({
    width: "38.8em",
    left: 0,
    position: "absolute" as "absolute",
    opacity: transition >= 3 ? 1 : 0,
    config: { duration: 300 },
  });

  const propsSecondMsgOptions = useSpring({
    width: "38.8em",
    left: 0,
    // 51(half height) + 78 (margin) (129*100/929 13.88vh)
    top: transition > 3 ? "calc(50vh + 13.88vh)" : "calc(50vh + 18vh)",
    position: "absolute" as "absolute",
    opacity: transition === 4 ? 1 : 0,
    config: { duration: 600 },
  });

  const propsSecondMsgAnswer = useSpring({
    zIndex: transition >= 5 ? 10 : -1,
    width: "38.8em",
    left: 0,
    top: transition > 3 ? "calc(50vh + 13.88vh)" : "calc(50vh + 18vh)",
    position: "absolute" as "absolute",
    opacity: transition >= 5 ? 1 : 0,
    config: { duration: 600 },
  });

  const propsThirdMsg = useSpring({
    zIndex: transition >= 6 ? 10 : -1,
    width: "38.8em",
    left: 0,
    top: "calc(50vh + 29.38vh)",
    position: "absolute" as "absolute",
    opacity: transition >= 6 ? 1 : 0,
    config: { duration: 1200 },
  });

  const propsNotification = useSpring({
    zIndex: transition >= 7 ? 10 : -1,
    width: "38.8em",
    left: 0,
    top: "calc(50vh + 48.76vh)",
    position: "absolute" as "absolute",
    opacity: transition >= 7 ? 1 : 0,
    config: { duration: 1200 },
  });

  const propsFourMsg = useSpring({
    zIndex: transition >= 8 ? 10 : -1,
    width: "38.8em",
    left: 0,
    top: "calc(50vh + 67.38vh)",
    position: "absolute" as "absolute",
    opacity: transition >= 8 ? 1 : 0,
    config: { duration: 1200 },
  });

  const propsFiveMsg = useSpring({
    zIndex: transition >= 9 ? 10 : -1,
    width: "38.8em",
    left: 0,
    top: "calc(50vh + 82.88vh)",
    position: "absolute" as "absolute",
    opacity: transition >= 9 ? 1 : 0,
    config: { duration: 1200 },
  });

  const propsBtn = useSpring({
    opacity: transition >= 10 ? 1 : 0,
    config: { duration: 600 },
  });

  return (
    <>
      <Backdrop show={transition > 10} from={"#00000099"} to={"transparent"} />
      <Box style={{ ...styles.container }}>
        <animated.div style={{ ...styles.containerBox, ...propsOpacityContainer }}>
          <animated.div style={{ ...propsCharacterRunner }}>
            <img src={characterRunner} alt="" style={{ width: "100%" }} />
          </animated.div>
          <animated.div style={{ ...propsCharacterAgent }}>
            <img src={characterAgent} alt="" style={{ width: "100%" }} />
          </animated.div>
          <animated.div style={{ ...styles.chatBox, ...propsChatBox }}>
            <animated.div style={{ ...propsFirstMsg }}>
              <Message name={t("agent.stage_two.chat.one.name") as string} message={t("agent.stage_two.chat.one.message") as string} />
            </animated.div>
            <animated.div style={{ ...propsSecondMsgOptions }}>
              <Box sx={styles.btnBox}>
                <Button
                  onClick={() => {
                    setAnswerChoosed("agent.stage_two.chat.two.option_one");
                    setStory({ transition: transition + 1, animating: true });
                  }}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {t("agent.stage_two.chat.two.option_one")}
                </Button>
              </Box>
              <Box sx={{ ...styles.btnBox, marginTop: "1.041em" }}>
                <Button
                  onClick={() => {
                    setAnswerChoosed("agent.stage_two.chat.two.option_two");
                    setStory({ transition: transition + 1, animating: true });
                  }}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {t("agent.stage_two.chat.two.option_two")}
                </Button>
              </Box>
            </animated.div>
            <animated.div style={{ ...propsSecondMsgAnswer }}>
              <Message
                align="right"
                name={t("agent.stage_two.chat.two.name") as string}
                message={t(answerChoosed !== "" ? answerChoosed : "agent.stage_two.chat.two.option_one") as string}
              />
            </animated.div>
            <animated.div style={{ ...propsThirdMsg }}>
              <Message name={t("agent.stage_two.chat.three.name") as string} message={t("agent.stage_two.chat.three.message") as string} />
            </animated.div>
            <animated.div style={{ ...propsNotification }}>
              <Notification />
            </animated.div>
            <animated.div style={{ ...propsFourMsg }}>
              <Message
                align="right"
                name={t("agent.stage_two.chat.four.name") as string}
                message={t("agent.stage_two.chat.four.message") as string}
              />
            </animated.div>
            <animated.div style={{ ...propsFiveMsg }}>
              <Message name={t("agent.stage_two.chat.five.name") as string} message={t("agent.stage_two.chat.five.message") as string} />
            </animated.div>
          </animated.div>
          <animated.div style={{ ...styles.btnContainer, ...propsBtn }}>
            <Button onClick={() => setStory({ transition: transition + 1 })} variant="contained" endIcon={<ArrowForwardIcon />}>
              {t("agent.stage_two.btn_continue")}
            </Button>
          </animated.div>
        </animated.div>
      </Box>
    </>
  );
};

const styles = {
  container: {
    overflow: "hidden !important",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  containerBox: {
    position: "relative" as "relative",
    width: "38.8em",
    height: "100vh",
    display: "flex",
    zIndex: 0,
  },
  chatBox: {
    position: "relative" as "relative",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as "column",
  },
  btnBox: {
    justifyContent: "flex-end",
    display: "flex",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
    margin: "5em 0",
    zIndex: -1,
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.story.stage,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(StageTwo, mapStateToProps, mapDispatchToProps);
