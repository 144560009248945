import { useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { Box } from "@mui/material";

import screen_3_table_2 from "../../../../../../Assets/StoryOfficer/Group_1261152757.png";
import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
}

const Screen3 = (props: ScreenProps) => {
  const { screen, transition, setStory } = props;

  useEffect(() => {
    return () => {};
  }, [transition, setStory]);

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  return (
    <ScreenContainer>
      <Box sx={{ mt: "1em" }}>
        <Box sx={styles.text_1}>Passenger Details</Box>
        <Box sx={{ position: "relative" }}>
          <Box onClick={handleNextScreen} sx={{ cursor: "pointer", position: "absolute", top: "-1.1em", right: "-1em" }}>
            <HighlightOffIcon sx={{ fontSize: "2.03125em" }} />
          </Box>
          <img src={screen_3_table_2} alt="" style={styles.table} />
        </Box>
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  table: {
    margin: "auto",
    maxHeight: "calc(65vh - 8em)",
    marginTop: "1em",
    objectFit: "contain" as "contain",
    width: "100%",
  },
  text_1: {
    fontWeight: "bold",
    fontSize: "1.45833333em",
    lineHeight: "1.5835em",
    marginTop: "0.634em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen3, mapStateToProps, mapDispatchToProps);
