import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Box, Button, Stack } from "@mui/material";
import CallEndIcon from "@mui/icons-material/CallEnd";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

import imageCalling from "../../../../../../Assets/StoryOfficer/CALL_STACY.png";

import Message from "../../../../../Commons/Message";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  theme: Theme;
}

const Screen10 = (props: ScreenProps) => {
  const { transition, screen, setStory } = props;

  useEffect(() => {
    if (screen === 11 && transition >= 2 && transition < 4) setTimeout(() => setStory({ transition: transition + 1 }), 3000);
    if (screen === 11 && transition === 4) setTimeout(() => setStory({ transition: transition + 1, animating: false }), 3000);
    if (screen === 11 && transition === 6) setTimeout(() => setStory({ screen: screen + 1, transition: 2 }), 1500);

    return () => {};
  }, [transition, screen, setStory]);

  const propsMsgOne = useSpring({
    opacity: screen !== 11 || (screen === 11 && transition <= 2) ? 0 : 1,
    config: { duration: 600 },
  });

  const propsMsgTwo = useSpring({
    marginTop: "1.25em",
    opacity: screen !== 11 || (screen === 11 && transition <= 3) ? 0 : 1,
    config: { duration: 600 },
  });

  const propsBtn = useSpring({
    opacity: screen !== 11 || (screen === 11 && transition <= 4) ? 0 : 1,
    config: { duration: 600 },
  });

  const propsChat = useSpring({
    opacity: screen !== 11 || (screen === 11 && transition >= 6) ? 0 : 1,
    config: { duration: 600 },
  });

  const handleNextScreen = () => setStory({ transition: transition + 1 });

  return (
    <ScreenContainer>
      <Box sx={{ width: "53.38541667em" }}>
        <Stack direction={"row"} sx={{ justifyContent: "center", alignItems: "flex-start", marginTop: "2.60416667em" }}>
          <animated.div style={{ ...styles.people }}>
            <img src={imageCalling} alt="" style={styles.imageCalling} />
          </animated.div>
          <animated.div style={{ marginLeft: "7.08333333em", flex: 1, ...propsChat }}>
            <animated.div style={{ ...propsMsgOne }}>
              <Message name={"Stacy"} message={"Got it! I'll get on it right away. "} />
            </animated.div>
            <animated.div style={{ ...propsMsgTwo }}>
              <Message name={"Trevor"} message={"Thank you."} />
            </animated.div>
            <animated.div style={{ ...propsBtn }}>
              <Button sx={styles.btn} onClick={handleNextScreen} variant="contained" endIcon={<CallEndIcon />}>
                End Call
              </Button>
            </animated.div>
          </animated.div>
        </Stack>
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  topBox: {
    textAlign: "center" as "center",
    width: "100%",
    marginTop: "2.60416667em",
  },
  text_1: {
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  boxText: {
    marginTop: "0.41666667em",
  },
  text_2: {
    fontSize: "1.25em",
  },
  people: {
    objectFit: "contain" as "contain",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
  },
  imageCalling: {
    width: "19.352vh",
  },
  btn: {
    marginTop: "2.1875em",
    textAlign: "left",
    justifycontent: "flex-start",
    display: "flex",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen10, mapStateToProps, mapDispatchToProps);
