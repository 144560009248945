import GenericScreen from "../../../GenericScreen";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import GenericPhoneScreen from "../../../GenericPhoneScreen";
import imgScreen from "../../../../../../Assets/StoryPassenger/PHONE_10.jpg";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

interface ScreenProps {
  transition: number;
}

const Screen = (props: ScreenProps) => {
  const { transition } = props;

  return (
    <GenericScreen
      showComponent={transition >= 4}
      showContent={true}
      icon={<PersonOutlineIcon sx={styles.icon} />}
      title={"Select your preferred seat"}
      text={"Looks like 2b will be convienient for mum to ask for assistance"}
      panelRight={<GenericPhoneScreen imgScreen={imgScreen} top={"41.5vh"} width={"4.7vh"} height={"4.9vh"} left={"10.35vh"} />}
    />
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

export default connectWithDispatch(Screen, mapStateToProps);
