import GenericScreen from "../../../GenericScreen";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import GenericPhoneScreen from "../../../GenericPhoneScreen";
import imgScreen from "../../../../../../Assets/StoryPassenger/PHONE_15.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

interface ScreenProps {
  transition: number;
}

const Screen = (props: ScreenProps) => {
  const { transition } = props;

  return (
    <GenericScreen
      showComponent={transition >= 4}
      showContent={true}
      icon={<NotificationsNoneIcon sx={styles.icon} />}
      title={"Cobalt Airlines"}
      text={"Looks like we have a delay. Find out more."}
      panelRight={<GenericPhoneScreen imgScreen={imgScreen} top={"20.7vh"} width={"37.95vh"} height={"9.6vh"} left={"1.5vh"} />}
    />
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

export default connectWithDispatch(Screen, mapStateToProps);
