import { Box, Button, Stack } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
  theme: Theme;
}

const Screen = (props: ScreenProps) => {
  const { t, setStory, screen } = props;
  const { secondary } = props.theme.palette.text;

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  return (
    <ScreenContainer>
      <Box sx={styles.subtitle}>{t("commons.subtitle_msg")}</Box>
      <Box sx={styles.welcome}>{t("officer.stage_two.screen_2.welcome")}</Box>
      <Box sx={styles.text}>{t("commons.text_welcome")}</Box>
      <Stack direction="row" sx={{ display: "flex", justifyContent: "center", marginTop: "1.9791em", height: "6.30208333em" }}>
        <Box>
          <Box style={styles.number}>{t("officer.stage_two.screen_2.box_one.number")}</Box>
          <Box style={styles.text_notification}>{t("officer.stage_two.screen_2.box_one.text")}</Box>
        </Box>
        <Box sx={{ marginRight: "2.9166em", marginLeft: "2.9166em" }}>
          <Box style={{ ...styles.number, color: secondary }}>{t("officer.stage_two.screen_2.box_two.number")}</Box>
          <Box style={styles.text_notification}>{t("officer.stage_two.screen_2.box_two.text")}</Box>
        </Box>
        <Box>
          <Box style={{ ...styles.number, color: "#df9926" }}>{t("officer.stage_two.screen_2.box_three.number")}</Box>
          <Box style={styles.text_notification}>{t("officer.stage_two.screen_2.box_three.text")}</Box>
        </Box>
      </Stack>
      <Button sx={styles.btn} onClick={handleNextScreen} variant="contained" endIcon={<ArrowForwardIcon />}>
        {t("officer.stage_two.screen_2.btn")}
      </Button>
    </ScreenContainer>
  );
};

const styles = {
  btn: {
    marginTop: "2.1875em",
  },
  subtitle: {
    fontSize: "1.25em",
  },
  welcome: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.2038em",
    fontSize: "2.8125em",
    marginTop: "0.4166em",
  },
  text: {
    fontSize: "1.25em",
    marginTop: "1.25em",
  },
  number: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    lineHeight: "1.2038em",
    fontSize: "2.8125em",
  },
  text_notification: {
    marginTop: "0.5835em",
    fontSize: "1.25em",
    lineHeight: "1.1625em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
