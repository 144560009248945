import GenericScreen from "../GenericScreen";
import { Box } from "@mui/material";

import imgScreen from "../../../../../../Assets/StoryRunner/Screen18.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

import ChatOne from "./ChatOne";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const Screen = (props: ScreenProps) => {
  const { transition, setStory, screen } = props;

  const handleNextScreen = () => {
    setTimeout(() => setStory({ screen: screen + 1 }), 1000);
  };

  return (
    <>
      <GenericScreen
        showComponent={transition >= 4}
        showContent={true}
        panelLeft={<ChatOne />}
        panelRight={<PhoneScreenOne handleNextScreen={handleNextScreen} />}
      />
    </>
  );
};

const styles = {
  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
    borderRadius: "26px",
  },
};

interface PhoneScreenOneProps {
  handleNextScreen: () => void;
}

const PhoneScreenOne = (props: PhoneScreenOneProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        onClick={props.handleNextScreen}
        sx={{ cursor: "pointer", position: "absolute", bottom: "7.4vh", width: "8.8vh", height: "9vh", left: "16vh" }}
      ></Box>
      <img style={styles.imgScreen} src={imgScreen} alt="" />
    </Box>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
