import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";

import useImagePreloader from "../../../../../Hooks/useImagePreloader";

import bg from "../../../../../Assets/StoryAgent/ipad_with_gloves.png";
import logo from "../../../../../Assets/Layer_3.png";

import Backdrop from "../../../../Commons/Backdrop";

import Screen1 from "./Tablet/Screen1";
import Screen2 from "./Tablet/Screen2";
import Screen3 from "./Tablet/Screen3";
import Screen4 from "./Tablet/Screen4";
import Screen5 from "./Tablet/Screen5";
import Screen6 from "./Tablet/Screen6";
import Screen7 from "./Tablet/Screen7";
import Screen8 from "./Tablet/Screen8";
import Screen9 from "./Tablet/Screen9";
import Screen10 from "./Tablet/Screen10";
import Screen11 from "./Tablet/Screen11";
import Screen12 from "./Tablet/Screen12";
import Screen13 from "./Tablet/Screen13";
import Screen14 from "./Tablet/Screen14";
import Screen15 from "./Tablet/Screen15";

import connectWithDispatch from "../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../Actions/appActions";

import ScreenAnimator from "../../../../Commons/ScreenAnimator";

const mediaItems: MediaType[] = [
  {
    type: "img",
    src: bg,
  },
];

interface StageProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const StageThree = (props: StageProps) => {
  const { t, transition, setStory, screen } = props;
  const { imagesPreloaded } = useImagePreloader(mediaItems);

  const animacion = useSpring(
    transition >= 2
      ? {
          from: {
            transform: "translateY(120%) rotate(-30deg)",
          },
          to: {
            transform: "translateY(0%) rotate(0deg)",
          },
          config: {
            duration: 500,
            tension: 300,
            friction: 20,
          },
        }
      : {
          transform: "translateY(120%) rotate(-30deg)",
        }
  );

  useEffect(() => {
    if (transition === 1 && screen === 0 && imagesPreloaded) setTimeout(() => setStory({ transition: transition + 1, screen: 1 }), 100);

    return () => {};
  }, [imagesPreloaded, transition, screen, setStory]);

  const { innerWidth, innerHeight } = window;
  const currentAspectRatio = innerWidth / innerHeight;

  return (
    <>
      <Backdrop show={transition > 1} from={"#00000099"} to={"transparent"} />
      <animated.div
        style={{
          background: `url(${bg}) center center/${currentAspectRatio < 1.7 ? "cover" : "contain"}`,
          ...styles.bgTablet,
          ...animacion,
        }}
      >
        <Stack sx={styles.screenContainer}>
          <Stack direction="row">
            <img src={logo} alt="" />
            <Box sx={styles.name_app}>{t("agent.stage_three.menu.name_app")}</Box>
            <Box sx={styles.menuItem}>{t("agent.stage_three.menu.menu_item_1")}</Box>
            <Box sx={styles.menuItem}>{t("agent.stage_three.menu.menu_item_2")}</Box>
            <Box sx={styles.menuItem}>{t("agent.stage_three.menu.menu_item_3")}</Box>
          </Stack>

          <ScreenAnimator
            currentScreen={screen - 1}
            screens={[
              <Screen1 />,
              <Screen2 />,
              <Screen3 />,
              <Screen4 />,
              <Screen5 />,
              <Screen6 />,
              <Screen7 />,
              <Screen8 />,
              <Screen9 />,
              <Screen10 />,
              <Screen11 />,
              <Screen12 />,
              <Screen13 />,
              <Screen14 />,
              <Screen15 />,
            ]}
          />
        </Stack>
      </animated.div>
    </>
  );
};

const styles = {
  bgTablet: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
  },
  screenContainer: {
    display: "flex",
    height: "74.075vh",
    maxHeight: "74.075vh",
    width: "118.519vh",
    maxWidth: "118.519vh",
  },
  screen: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuItem: {
    marginRight: "2em",
    fontSize: "1.0416em",
    fontFamily: "Arial",
    display: "flex",
    alignItems: "center",
  },
  name_app: {
    fontFamily: "Arial",
    flex: 1,
    fontWeight: "bold",
    fontSize: "1.25em",
    display: "flex",
    alignItems: "center",
    paddingLeft: "2.0835em",
    letterSpacing: "1px",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(StageThree, mapStateToProps, mapDispatchToProps);
