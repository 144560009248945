import { useSpring, animated } from "@react-spring/web";

interface BallProps {
  show: boolean;
  granted: boolean;
  top: string;
  left: string;
}

const Ball = (props: BallProps) => {
  const randomDuration = () => {
    return Math.floor(Math.random() * (1500 - 100 + 1)) + 100;
  };

  const propsOpacityBall = useSpring({
    opacity: props.show ? 1 : 0,
    config: { duration: randomDuration() },
  });

  const propsColorBall = useSpring({
    background: props.granted ? "#00B28F" : "white",
    config: { duration: randomDuration() },
  });

  return <animated.div style={{ ...styles.ball, left: props.left, top: props.top, ...propsOpacityBall, ...propsColorBall }}></animated.div>;
};

const styles = {
  ball: {
    position: "absolute" as "absolute",
    borderRadius: "100px",
    padding: "0.396em",
  },
};

export default Ball;
