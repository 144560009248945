import { useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Box, Button } from "@mui/material";

import screen_3_table_2 from "../../../../../../Assets/StoryOfficer/Group_1261152870.png";

import video from "../../../../../../Assets/StoryOfficer/video.mp4";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const Screen5 = (props: ScreenProps) => {
  const { t, screen, transition, setStory } = props;

  useEffect(() => {
    return () => {};
  }, [transition, setStory]);

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  return (
    <ScreenContainer>
      <Box sx={{ position: "relative" }}>
        <img src={screen_3_table_2} alt="" style={styles.table} />
        <Button sx={styles.btn} onClick={handleNextScreen} variant="contained" endIcon={<ArrowForwardIcon />}>
          {t("officer.stage_two.screen_5.btn")}
        </Button>
        <video src={video} style={styles.video} controls autoPlay={true}></video>
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  table: {
    margin: "auto",
    maxHeight: "calc(65vh - 8em)",
    marginTop: "1em",
    objectFit: "contain" as "contain",
    width: "100%",
  },
  btn: {
    position: "absolute",
    left: "2em",
    bottom: "2em",
  },
  video: {
    position: "absolute" as "absolute",
    right: "1em",
    top: "10vh",
    height: "36vh",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen5, mapStateToProps, mapDispatchToProps);
