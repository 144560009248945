import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { Box, Stack } from "@mui/material";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

interface NotificationProps {
  t: (key: string) => ReactNode;
  theme: Theme;
}

const Notification = (props: NotificationProps) => {
  const { t } = props;
  const { secondary } = props.theme.palette.text;

  return (
    <Stack direction="row" sx={{ ...styles.notification, color: secondary, border: `1px solid ${secondary}` }}>
      <Box sx={styles.iconContainer}>
        <ReportGmailerrorredIcon sx={styles.icon} />
      </Box>
      <Box>
        <Box sx={styles.title}>
          {t("agent.stage_two.notification_part_1")}: {t("agent.stage_two.notification_part_2")}
        </Box>
      </Box>
    </Stack>
  );
};

const styles = {
  notification: {
    background: "#000000a1",
    width: "100%",
    fontWeight: "bold",
    padding: "0.5em",
    fontSize: "0.813em",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "0.8em",
  },
  icon: {
    fontSize: "2em",
  },
  title: {
    paddingLeft: "1em",
    fontSize: "1.25em",
    lineHeight: "2em",
  },
};

export default connectWithDispatch(Notification);
