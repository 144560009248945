import { Box } from "@mui/material";

import imgInfo from "../../../../../../Assets/StoryAgent/screen_4.png";
import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
  theme: Theme;
}

const Screen = (props: ScreenProps) => {
  const { t, screen, setStory } = props;
  const { main } = props.theme.palette.error;

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  return (
    <ScreenContainer>
      <Box sx={styles.topBox}>
        <Box sx={styles.title}>{t("commons.title")}</Box>
        <Box sx={styles.text_2}>
          {t("agent.stage_three.screen_4.text_2_part_1")}
          <span style={{ ...styles.span, color: main }}>{t("agent.stage_three.screen_4.text_2_part_2")}</span>
          {t("agent.stage_three.screen_4.text_2_part_3")}
        </Box>
      </Box>
      <Box onClick={handleNextScreen}>
        <img src={imgInfo} alt="" style={styles.imgInfo} />
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  imgInfo: {
    margin: "auto",
    maxHeight: "calc(65vh - 8em)",
    marginTop: "1em",
    objectFit: "contain" as "contain",
    width: "100%",
  },
  topBox: {
    textAlign: "center",
    width: "100%",
    border: "1px solid #fff",
    padding: "1.09375em",
  },
  title: {
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  text_2: {
    fontSize: "1.25em",
    marginTop: "1em",
  },
  text_3: {
    fontSize: "1.25em",
  },
  span: {
    fontWeight: "bold",
    fontSize: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
