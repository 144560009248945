import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ScreenContainer from "./ScreenContainer";

import picture_profile from "../../../../../../Assets/Stages/stacy.jpg";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
}

const Screen2 = (props: ScreenProps) => {
  const { screen, setStory } = props;

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  return (
    <ScreenContainer>
      <Box sx={{ mt: "1em" }}>
        <img src={picture_profile} style={styles.picture_profile} alt="" />
        <Box sx={styles.text_1}>Message from Stacy</Box>
        <Box sx={styles.boxText}>
          <Box sx={styles.text_2}>Looks like it was a family of 4.</Box>
          <Box sx={styles.text_2}>They had misplaced their bag and were unawere.</Box>
        </Box>
        <Box sx={styles.boxText}>
          <Box sx={styles.text_2}>I have indentified the passengers and sent across their travel details.</Box>
          <Box sx={styles.text_2}>Will clear them if it is all good on your end.</Box>
        </Box>
        <Box sx={styles.btn}>
          <Button onClick={handleNextScreen} variant="contained" endIcon={<ArrowForwardIcon />}>
            See passengers details
          </Button>
        </Box>
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  btn: {
    marginTop: "2.1875em",
  },
  boxText: {
    margin: "auto",
    marginTop: "1em",
    width: "27.60416667em",
  },
  text_1: {
    fontWeight: "bold",
    fontSize: "1.45833333em",
    lineHeight: "1.5835em",
    marginTop: "0.634em",
  },
  text_2: {
    margin: "auto",
    fontSize: "1.25em",
  },
  text_3: {
    margin: "auto",
    fontSize: "1.25em",
  },
  picture_profile: {
    width: "4.74em",
    borderRadius: "50px",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen2, mapStateToProps, mapDispatchToProps);
