import GenericScreen from "../../../GenericScreen";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import GenericPhoneScreen from "../../../GenericPhoneScreen";
import imgScreen from "../../../../../../Assets/StoryPassenger/PHONE_3.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

interface ScreenProps {
  transition: number;
}

const Screen = (props: ScreenProps) => {
  const { transition } = props;

  return (
    <GenericScreen
      showComponent={transition >= 4}
      showContent={true}
      icon={<SentimentSatisfiedAltIcon sx={styles.icon} />}
      title={"Welcome back Elena"}
      text={"Go through the steps and complete your online check-in for your upcoming flight to London."}
      panelRight={<GenericPhoneScreen imgScreen={imgScreen} bottom={"3.85vh"} width={"34.5vh"} height={"7vh"} left={"3.35vh"} />}
    />
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

export default connectWithDispatch(Screen, mapStateToProps);
