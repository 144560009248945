import GenericScreen from "../../../GenericScreen";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import GenericPhoneScreen from "../../../GenericPhoneScreen";
import imgScreen from "../../../../../../Assets/StoryPassenger/PHONE_20.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  setScreenPopUp: (value: ScreenPopUpType | null) => void;
}

const Screen = (props: ScreenProps) => {
  const { transition, setScreenPopUp } = props;

  const handleExit = () => setScreenPopUp("storyEnded");

  return (
    <GenericScreen
      showComponent={transition >= 4}
      showContent={true}
      icon={<NotificationsNoneIcon sx={styles.icon} />}
      title={"Cobalt Airlines"}
      text={"Looks like it’s time to board. Let’s head to the gate."}
      panelRight={
        <GenericPhoneScreen
          handleNextScreen={handleExit}
          imgScreen={imgScreen}
          top={"20.7vh"}
          width={"37.95vh"}
          height={"9.6vh"}
          left={"1.5vh"}
        />
      }
    />
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setScreenPopUp: appActions.setScreenPopUp,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
