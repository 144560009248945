import GenericScreen from "../../../GenericScreen";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import GenericPhoneScreen from "../../../GenericPhoneScreen";
import imgScreen from "../../../../../../Assets/StoryPassenger/PHONE_8.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

interface ScreenProps {
  transition: number;
}

const Screen = (props: ScreenProps) => {
  const { transition } = props;

  return (
    <GenericScreen
      showComponent={transition >= 4}
      showContent={true}
      icon={<FavoriteBorderIcon sx={styles.icon} />}
      title={"Intuitive Assistance"}
      text={"Perfect looks like they will be able to pick up Mum at the entrance."}
      panelRight={<GenericPhoneScreen imgScreen={imgScreen} top={"29.1vh"} width={"33.9vh"} height={"5.7vh"} left={"3.35vh"} />}
    />
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

export default connectWithDispatch(Screen, mapStateToProps);
