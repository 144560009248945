import { Box } from "@mui/material";

interface ScreenContainerProps {
  children: ReactNode;
  height?: string;
}

const ScreenContainer = (props: ScreenContainerProps) => {
  const { height } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxHeight: "100%",
        textAlign: "center",
        ...(height ? { height } : {}),
      }}
    >
      <Box sx={{ width: "100%" }}>{props.children}</Box>
    </Box>
  );
};

export default ScreenContainer;
