import * as Types from "../Constants/Types";

const initialState: AppReducer = {
  isLoading: true,
  stage: 1,
  transition: 0,
  story: {
    character: null,
    stage: 1,
    transition: 0,
    screen: 0,
    animating: false,
  },
  screenPopUp: null,
};

type SetLoadingAction = { type: typeof Types.SET_IS_LOADING; value: boolean };
type SetStageAction = { type: typeof Types.SET_STAGE; value: number };
type SetTransitionAction = { type: typeof Types.SET_TRANSITION; value: { stage: number; transition: number } };
type SetStoryAction = { type: typeof Types.SET_STORY; value: Partial<StoryType> };
type SetScreenPopUpAction = { type: typeof Types.SET_SCREEN_POPUP; value: ScreenPopUpType | null };

type AppReducerAction = SetLoadingAction | SetStageAction | SetTransitionAction | SetStoryAction | SetScreenPopUpAction;

export default function appReducer(state = initialState, action: AppReducerAction) {
  switch (action.type) {
    case Types.SET_IS_LOADING: {
      return { ...state, isLoading: action.value };
    }
    case Types.SET_STAGE: {
      return { ...state, transition: 0, chat: [], stage: action.value };
    }
    case Types.SET_TRANSITION: {
      return { ...state, transition: state.stage === action.value.stage ? action.value.transition : state.transition };
    }
    case Types.SET_STORY: {
      return { ...state, story: { ...state.story, ...action.value } };
    }
    case Types.SET_SCREEN_POPUP: {
      return { ...state, screenPopUp: action.value };
    }
    default:
      return state;
  }
}
