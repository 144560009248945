import connectWithDispatch from "../../Hooks/connectWithDispatch";
import GenericFinishedStory from "../Commons/GenericFinishedStory";

interface FinishedStoryScreenProps {
  screenPopUp: "backHome" | "backCharacters" | "storyEnded";
  character: CharacterType;
}

const FinishedStoryScreen = (props: FinishedStoryScreenProps) => {
  const { screenPopUp, character } = props;

  return <>{screenPopUp === "storyEnded" && character !== null && <GenericFinishedStory />}</>;
};

const mapStateToProps = (state: StateType) => {
  return {
    screenPopUp: state.appReducer.screenPopUp,
    character: state.appReducer.story.character,
  };
};

export default connectWithDispatch(FinishedStoryScreen, mapStateToProps);
