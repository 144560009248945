import { useSpring, animated } from "@react-spring/web";
import { Box, Stack } from "@mui/material";

interface PanelLeftProps {
  icon?: ReactNode;
  title?: string;
  text?: string;
  extra?: ReactNode;
  showComponent: boolean;
  showContent: boolean;
  srcImg?: string;
  panelLeft?: ReactNode;
  panelRight: ReactNode;
}

const GenericScreen = (props: PanelLeftProps) => {
  const { icon, title, text, extra, showComponent = true, srcImg, panelLeft, panelRight } = props;

  const propsBoxComponent = useSpring({
    opacity: showComponent ? 1 : 0,
    config: { duration: 600 },
  });

  const propsBoxContent = useSpring({
    opacity: showComponent ? 1 : 0,
    config: { duration: 600 },
  });

  return (
    <Stack direction={"row"} sx={{ alignItems: "center" }}>
      <Box sx={{ width: "78.0556vh", marginLeft: "1.5625em", height: "42.2222vh" }}>
        <animated.div style={{ ...propsBoxComponent, ...styles.boxBlack }}>
          <animated.div style={{ ...propsBoxContent }}>
            {panelLeft && <>{panelLeft}</>}
            {!panelLeft && (
              <Stack direction={"row"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {icon && <>{icon}</>}
                  <Box style={styles.title}>{title}</Box>
                  <Box style={styles.text}>{text}</Box>
                  {extra && <Box>{extra}</Box>}
                </Box>
                {srcImg && (
                  <Box style={{ margin: "1.30208333em" }}>
                    <img src={srcImg} alt="" />
                  </Box>
                )}
              </Stack>
            )}
          </animated.div>
        </animated.div>
      </Box>
      <Box
        sx={{
          width: "40.9259vh",
          marginLeft: "1.25em",
          height: "88.5185vh",
          marginBottom: "0.625em",
        }}
      >
        {panelRight}
      </Box>
    </Stack>
  );
};

const styles = {
  boxBlack: {
    background: "#000000BF",
    borderRadius: "10px 0 0 10px",
    paddingLeft: "5.20833333em",
    paddingRight: "3.64583333em",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    justifyContent: "center" as "center",
    height: "51vh",
  },
  title: {
    fontSize: "1.45833333em",
    fontWeight: "bold",
  },
  text: {
    fontSize: "1.25em",
  },
};

export default GenericScreen;
