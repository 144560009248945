import React from "react";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";

import * as appActions from "../../Actions/appActions";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

interface BackHomeProps {
  setScreenPopUp: (value: ScreenPopUpType | null) => void;
  disabled?: boolean;
}

function BackHome(props: BackHomeProps) {
  const { setScreenPopUp, disabled = false } = props;

  const backHome = () => (!disabled ? setScreenPopUp("backHome") : "");

  return (
    <Box sx={styles.container} onClick={backHome}>
      <HomeIcon sx={{ color: disabled ? "#e0e0e0" : "white" }} />
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "0.5em",
    color: "white",
  },
};

const mapStateToProps = (state: StateType) => {
  return {};
};

const mapDispatchToProps: MapDispatchToProps = {
  setScreenPopUp: appActions.setScreenPopUp,
};

export default connectWithDispatch(BackHome, mapStateToProps, mapDispatchToProps);
