import { Box, Stack } from "@mui/material";

import DictionaryPictures from "../../../Constants/DictionaryPictures";

import connectWithDispatch from "../../../Hooks/connectWithDispatch";

import "./CardEmployee.css";

interface CardEmployeeProps {
  keyEmployee: EmployeeType;
  t: (key: string) => ReactNode;
}

const CardEmployee = (props: CardEmployeeProps) => {
  const { t, keyEmployee } = props;

  const name = t(`agent.stage_three.screen_7.${keyEmployee}.name`);
  const position = t(`agent.stage_three.screen_7.${keyEmployee}.position`);
  const description = t(`agent.stage_three.screen_7.${keyEmployee}.description`);

  return (
    <Box className="CardEmployee" sx={styles.container}>
      <Stack direction="row">
        <Box>
          <img src={DictionaryPictures[keyEmployee as EmployeeType]} alt="" />
        </Box>
        <Box sx={styles.details}>
          <Box sx={styles.name}>{name}</Box>
          <Box sx={styles.position}>{position}</Box>
          <Box sx={styles.description}>{description}</Box>
        </Box>
      </Stack>
    </Box>
  );
};

const styles = {
  container: {
    background: "#000",
    padding: "1em",
    cursor: "pointer",
    textAlign: "left",
    border: "4px solid #fff",
  },
  details: {
    paddingLeft: "0.4em",
  },
  name: {
    fontSize: "1.04166667em",
    fontWeight: "bold",
    fontFamily: "SpaceMono",
  },
  position: {
    fontSize: "0.72916667em",
    fontFamily: "SpaceMono",
    textDecoration: "underline",
    color: "#E5E5E5",
  },
  description: {
    fontSize: "0.72916667em",
    fontFamily: "SpaceMono",
    color: "#E5E5E5",
  },
};

export default connectWithDispatch(CardEmployee);
