import GenericScreen from "../../../GenericScreen";
import { Box } from "@mui/material";

import imgScreen from "../../../../../../Assets/StoryPassenger/PHONE_18.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
}

const Screen = (props: ScreenProps) => {
  const { transition, setStory, screen } = props;

  const handleNextScreen = () => setTimeout(() => setStory({ screen: screen + 1 }), 1000);

  return (
    <GenericScreen
      showComponent={transition >= 4}
      showContent={true}
      title={"Hyper-personalised offers"}
      text={"Tailored promotions just for you! Maybe you can buy a few last minute presents before you board the plane."}
      panelRight={<PhoneScreenOne handleNextScreen={handleNextScreen} />}
    />
  );
};

interface PhoneScreenOneProps {
  handleNextScreen: () => void;
}

const PhoneScreenOne = (props: PhoneScreenOneProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        onClick={props.handleNextScreen}
        sx={{
          cursor: "pointer",
          position: "absolute",
          top: "44.7vh",
          width: "33.3vh",
          height: "5.1vh",
          left: "3.6vh",
        }}
      ></Box>

      <Box
        onClick={props.handleNextScreen}
        sx={{
          cursor: "pointer",
          position: "absolute",
          bottom: "6vh",
          width: "33.3vh",
          height: "5.1vh",
          left: "3.6vh",
        }}
      ></Box>
      <img style={styles.imgScreen} src={imgScreen} alt="" />
    </Box>
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
    borderRadius: "26px",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
