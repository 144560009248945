import { useEffect } from "react";

import screen_3_table_1 from "../../../../../../Assets/StoryOfficer/Group_1261152867.png";
import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
}

const Screen3 = (props: ScreenProps) => {
  const { screen, transition, setStory } = props;

  useEffect(() => {
    if (screen === 3 && transition === 2) setTimeout(() => setStory({ screen: screen + 1, transition: 2 }), 3000);

    return () => {};
  }, [transition, screen, setStory]);

  return (
    <ScreenContainer>
      <img src={screen_3_table_1} alt="" style={styles.table} />
    </ScreenContainer>
  );
};

const styles = {
  table: {
    margin: "auto",
    maxHeight: "calc(65vh - 8em)",
    marginTop: "1em",
    objectFit: "contain" as "contain",
    width: "100%",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen3, mapStateToProps, mapDispatchToProps);
