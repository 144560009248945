import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

const connectWithDispatch = (Component: any, mapStateToProps?: any, mapDispatchToProps?: any) => {
  let Connect = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Component));
  return (props: any) => <Connect {...props} dispatch={useDispatch()} theme={useTheme()} />;
};

export default connectWithDispatch;
