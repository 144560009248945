import { useEffect } from "react";

import { Box } from "@mui/material";

import screen_3_table_2 from "../../../../../../Assets/StoryOfficer/Group_1261152868.png";
import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
}

const Screen3 = (props: ScreenProps) => {
  const { screen, transition, setStory } = props;

  useEffect(() => {
    return () => {};
  }, [transition, setStory]);

  const handleNextScreen = () => setStory({ screen: screen + 1, transition: 2 });

  return (
    <ScreenContainer>
      <Box sx={{ position: "relative" }}>
        <Box
          onClick={handleNextScreen}
          sx={{ cursor: "pointer", position: "absolute", top: "2.9em", width: "18.6em", height: "9.6em" }}
        ></Box>
        <img src={screen_3_table_2} alt="" style={styles.table} />
      </Box>
    </ScreenContainer>
  );
};

const styles = {
  table: {
    margin: "auto",
    maxHeight: "calc(65vh - 8em)",
    marginTop: "1em",
    objectFit: "contain" as "contain",
    width: "100%",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen3, mapStateToProps, mapDispatchToProps);
