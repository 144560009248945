import { Box, Stack } from "@mui/material";

import ScreenContainer from "./ScreenContainer";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

import CardEmployee from "../../../../../Commons/CardEmployee";

interface ScreenProps {
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const Screen = (props: ScreenProps) => {
  const { t, screen, setStory } = props;

  const handleSam = () => setStory({ screen: screen + 2, transition: 2, animating: true });
  const handleBrian = () => setStory({ screen: screen + 1, transition: 2, animating: true });

  return (
    <ScreenContainer>
      <Box sx={styles.topBox}>
        <Box sx={styles.title}>{t("commons.title")}</Box>
        <Box sx={styles.text_2}>{t("agent.stage_three.screen_7.text_2")}</Box>
      </Box>
      <Stack sx={styles.people} direction={"row"}>
        <Box onClick={handleSam}>
          <CardEmployee keyEmployee={"sam"} />
        </Box>

        <Box onClick={handleBrian}>
          <CardEmployee keyEmployee={"brian"} />
        </Box>
      </Stack>
    </ScreenContainer>
  );
};

const styles = {
  topBox: {
    textAlign: "center",
    width: "100%",
  },
  title: {
    margin: "auto",
    fontSize: "1.4583em",
    fontWeight: "bold",
  },
  text_2: {
    margin: "auto",
    width: "42em",
    fontSize: "1.25em",
    marginTop: "1em",
  },
  people: {
    margin: "auto",
    objectFit: "contain" as "contain",
    height: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    display: "flex",
    mt: "4.0625em",
    width: "48.959em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
