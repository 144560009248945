import MainView from "./Components/MainView";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/joy/CircularProgress";
import { Box } from "@mui/material";

import Options from "./Components/Commons/Options";

import muiTheme from "./Configs/muiTheme.json";

import connectWithDispatch from "./Hooks/connectWithDispatch";

import ExitScreen from "./Components/Screens/ExitScreen";
import FinishedStoryScreen from "./Components/Screens/FinishedStoryScreen";

import useImagePreloader from "./Hooks/useImagePreloader";

import videoIntro from "./Assets/Stages/INTRO.mp4";
import videoAgent from "./Assets/StoryAgent/GHAFull.mp4";
import videoOfficer from "./Assets/StoryOfficer/CTFull.mp4";
import videoRunner from "./Assets/StoryRunner/BRFull.mp4";
import videoPassenger from "./Assets/StoryPassenger/PassengerFull.mp4";

import IntroFrame from "./Assets/Stages/bkg-intro.jpg";
import IntroLastFrame from "./Assets/Stages/bkg-characterselection.jpg";
import bg_GHA from "./Assets/StoryAgent/bkg-gha.jpg";
import bg_CT from "./Assets/StoryOfficer/bkg-controltower.jpg";
import bg_BH from "./Assets/StoryRunner/bkg-bagrunner.jpg";
import bg_PA from "./Assets/StoryPassenger/bkg-passenger.jpg";

import image_1 from "./Assets/Stages/mom.png";
import image_2 from "./Assets/Stages/officer.png";
import image_3 from "./Assets/Stages/runner.png";
import image_4 from "./Assets/Stages/agent.png";

import image_5 from "./Assets/StoryAgent/Melissa.png";
import image_6 from "./Assets/StoryAgent/Dave.png";
import image_7 from "./Assets/StoryAgent/melissa_left.png";
import image_8 from "./Assets/StoryAgent/screen_13.jpg";
import image_9 from "./Assets/StoryAgent/screen_4.png";
import image_10 from "./Assets/StoryAgent/screen_5.png";
import image_11 from "./Assets/StoryAgent/screen_6.png";

import video_1 from "./Assets/StoryOfficer/video.mp4";
import image_12 from "./Assets/StoryOfficer/SecurityComputerFrontFace_2.png";
import image_13 from "./Assets/StoryOfficer/Security_1.png";

import image_14 from "./Assets/StoryPassenger/MOM_1.png";
import image_15 from "./Assets/StoryPassenger/PHONE_1.png";
import image_16 from "./Assets/StoryPassenger/PHONE_2.png";
import image_17 from "./Assets/StoryPassenger/PHONE_3.png";
import image_18 from "./Assets/StoryPassenger/PHONE_5.png";
import image_19 from "./Assets/StoryPassenger/PHONE_6.png";
import image_20 from "./Assets/StoryPassenger/PHONE_7.png";
import image_21 from "./Assets/StoryPassenger/PHONE_8.png";
import image_22 from "./Assets/StoryPassenger/PHONE_9.png";
import image_23 from "./Assets/StoryPassenger/MOM_SIDE.png";
import image_24 from "./Assets/StoryPassenger/WORKER_SIDE.png";
import image_25 from "./Assets/StoryPassenger/KID_SIDE.png";
import image_26 from "./Assets/StoryPassenger/PHONE_15.png";
import image_27 from "./Assets/StoryPassenger/PHONE_16.jpg";
import image_28 from "./Assets/StoryPassenger/PHONE_17.png";
import image_29 from "./Assets/StoryPassenger/PHONE_18.png";
import image_30 from "./Assets/StoryPassenger/PHONE_20.png";

import Roboto from "./Assets/Fonts/Roboto-Bold.ttf";
import MyriadPro from "./Assets/Fonts/MyriadPro-Semibold.otf";
import SpaceMonoRegular from "./Assets/Fonts/SpaceMono-Regular.ttf";
import SpaceMonoBold from "./Assets/Fonts/SpaceMono-Bold.ttf";
import Tungsten from "./Assets/Fonts/Tungsten-Medium.ttf";

const mediaItems: MediaType[] = [
  { type: "video", src: videoIntro },
  { type: "video", src: videoAgent },
  { type: "video", src: videoOfficer },
  { type: "video", src: videoRunner },
  { type: "video", src: videoPassenger },
  { type: "img", src: IntroFrame },
  { type: "img", src: IntroLastFrame },
  { type: "img", src: bg_GHA },
  { type: "img", src: bg_CT },
  { type: "img", src: bg_BH },
  { type: "img", src: bg_PA },
  { type: "img", src: image_1 },
  { type: "img", src: image_2 },
  { type: "img", src: image_3 },
  { type: "img", src: image_4 },
  { type: "img", src: image_5 },
  { type: "img", src: image_6 },
  { type: "img", src: image_7 },
  { type: "img", src: image_8 },
  { type: "img", src: image_9 },
  { type: "img", src: image_10 },
  { type: "img", src: image_11 },
  { type: "video", src: video_1 },
  { type: "img", src: image_12 },
  { type: "img", src: image_13 },
  { type: "img", src: image_14 },
  { type: "img", src: image_15 },
  { type: "img", src: image_16 },
  { type: "img", src: image_17 },
  { type: "img", src: image_18 },
  { type: "img", src: image_19 },
  { type: "img", src: image_20 },
  { type: "img", src: image_21 },
  { type: "img", src: image_22 },
  { type: "img", src: image_23 },
  { type: "img", src: image_24 },
  { type: "img", src: image_25 },
  { type: "img", src: image_26 },
  { type: "img", src: image_27 },
  { type: "img", src: image_28 },
  { type: "img", src: image_29 },
  { type: "img", src: image_30 },
  { type: "font", src: Roboto, name: "Roboto" },
  { type: "font", src: MyriadPro, name: "MyriadPro" },
  { type: "font", src: MyriadPro, name: "MyriadPro" },
  { type: "font", src: SpaceMonoRegular, name: "SpaceMonoRegular" },
  { type: "font", src: SpaceMonoBold, name: "SpaceMonoBold" },
  { type: "font", src: Tungsten, name: "Tungsten" },
];

const App = () => {
  const mdTheme = createTheme(muiTheme as any);
  const { imagesPreloaded } = useImagePreloader(mediaItems);

  return (
    <>
      {!imagesPreloaded && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </Box>
      )}
      {imagesPreloaded && (
        <ThemeProvider theme={mdTheme}>
          <CssBaseline />
          <ExitScreen />
          <FinishedStoryScreen />
          <MainView />
          <Options />
        </ThemeProvider>
      )}
    </>
  );
};

export default connectWithDispatch(App);
