import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { useSpring, animated } from "@react-spring/web";

import bgIntro from "../../Assets/Stages/bkg-intro.jpg";

import HandleBg from "../Commons/HandleBg";
import Backdrop from "../Commons/Backdrop";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

import * as appActions from "../../Actions/appActions";

import logo from "../../Assets/Stages/image_82.png";

interface StageProps {
  stage: number;
  transition: number;
  setStage: (stage: number) => void;
  setTransition: (stage: number, transition: number) => void;
  t: (key: string) => ReactNode;
}

const StageOne = (props: StageProps) => {
  const { t, stage, setStage, transition, setTransition } = props;

  useEffect(() => {
    if (transition === 1) setTimeout(() => setTransition(stage, transition + 1), 400);
    if (transition === 2) setTimeout(() => setTransition(stage, transition + 1), 500);
    return () => {};
  }, [transition, setTransition, stage, setStage]);

  const propsState_1 = useSpring({
    opacity: transition === 0 ? 1 : 0,
  });

  const propsBtnSkip = useSpring({
    opacity: transition === 3 ? 1 : 0,
  });

  return (
    <>
      <animated.div style={{ ...styles.btn_skip, ...propsBtnSkip }}>
        <Button onClick={() => setTimeout(() => setStage(stage + 1), 100)} variant="contained" endIcon={<SkipNextIcon />}>
          {t("stage_one.btn_skip")}
        </Button>
      </animated.div>
      <HandleBg showBg={transition < 2} bg={bgIntro} />
      <Backdrop show={transition >= 2} from={"#00000066"} to={"transparent"} />
      <animated.div style={{ ...styles.container, ...propsState_1 }}>
        <img src={logo} alt="" style={styles.logo} />
        <Box style={styles.line}></Box>
        <Box sx={styles.title}>{t("stage_one.title")}</Box>
        <Box style={styles.line}></Box>
        <Box sx={styles.containerBtn}>
          <Button onClick={() => setTransition(stage, transition + 1)} variant="contained" endIcon={<ArrowForwardIcon />}>
            {t("stage_one.btn_start")}
          </Button>
        </Box>
      </animated.div>
    </>
  );
};

const styles = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column" as "column",
  },
  line: {
    width: "100%",
    borderTop: "0.01em solid #fff",
    marginTop: "1.3020em",
    marginBottom: "1.3020em",
  },
  title: {
    textAlign: "center",
    fontSize: "9.375em",
    lineHeight: "0.8778em",
    fontFamily: "Tungsten",
    textShadow: "0 0 8px #333",
  },
  logo: {
    width: "13em",
    margin: "0 auto",
  },
  subtitle: {
    textAlign: "center",
    fontSize: "1.25em",
    fontFamily: "Arial",
    lineHeight: "1.0835em",
  },
  containerBtn: {
    textAlign: "center",
    marginTop: "1.3020em",
  },
  btn_skip: {
    position: "absolute" as "absolute",
    right: "1em",
    bottom: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.stage,
    transition: state.appReducer.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStage: appActions.setStage,
  setTransition: appActions.setTransition,
};

export default connectWithDispatch(StageOne, mapStateToProps, mapDispatchToProps);
