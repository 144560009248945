import StageOne from "./Stages/StageOne";
import StageTwo from "./Stages/StageTwo";
import StageThree from "./Stages/StageThree";
import StageFour from "./Stages/StageFour";
import StageFive from "./Stages/StageFive";

import connectWithDispatch from "../../../Hooks/connectWithDispatch";

interface CharacterProps {
  stage: number;
}

const Character = (props: CharacterProps) => {
  const { stage } = props;

  return (
    <>
      {stage === 1 && <StageOne />}
      {stage === 2 && <StageTwo />}
      {stage === 3 && <StageThree />}
      {stage === 4 && <StageFour />}
      {stage === 5 && <StageFive />}
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.story.stage,
  };
};

export default connectWithDispatch(Character, mapStateToProps);
