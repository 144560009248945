import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import { Box } from "@mui/material";

import characterWorker from "../../../../Assets/StoryPassenger/WORKER_SIDE.png";
import characterPassenger from "../../../../Assets/StoryPassenger/MOM_SIDE.png";

import Message from "../../../Commons/Message";

import Backdrop from "../../../Commons/Backdrop";

import connectWithDispatch from "../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../Actions/appActions";

interface StageProps {
  stage: number;
  transition: number;
  setStory: (value: Partial<StoryType>) => void;
}

const StageFour = (props: StageProps) => {
  const { stage, transition, setStory } = props;

  const viewportHeight = window.innerHeight;

  const move165 = viewportHeight * 0.1776;

  useEffect(() => {
    if (transition >= 1 && transition < 4) setTimeout(() => setStory({ transition: transition + 1 }), 650);
    if (transition === 4) setTimeout(() => setStory({ transition: transition + 1 }), 1500);
    if (transition === 5) setTimeout(() => setStory({ transition: transition + 1 }), 2000);
    if (transition === 6) setTimeout(() => setStory({ transition: transition + 1 }), 2000);
    if (transition === 7) setTimeout(() => setStory({ transition: transition + 1 }), 4000);
    if (transition === 8) setTimeout(() => setStory({ stage: stage + 1, transition: 1, screen: 0 }), 1200);

    return () => {};
  }, [stage, transition, setStory]);

  const getTranslateYChatBox = () => {
    switch (transition) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return `translateY(0px)`;
      case 6:
        return `translateY(-${move165}px)`;
    }
  };

  const propsOpacityContainer = useSpring({
    opacity: transition >= 2 && transition < 8 ? 1 : 0,
    config: { duration: transition === 8 ? 300 : 800 },
  });

  const propsCharacterWorker = useSpring({
    zIndex: -1,
    position: "absolute" as "absolute",
    left: transition >= 9 ? "-150%" : "-79%",
    width: "63vh",
    bottom: "-0.5em",
    config: { duration: 600 },
  });

  const propsCharacterPassenger = useSpring({
    zIndex: -1,
    position: "absolute" as "absolute",
    right: transition >= 9 ? "-150%" : "-59%",
    width: "54.815vh",
    bottom: "-0.5em",
    config: { duration: 600 },
  });

  const propsChatBox = useSpring({
    transform: getTranslateYChatBox(),
    config: { duration: 300 },
  });

  const propsFirstMsg = useSpring({
    width: "38.8em",
    left: 0,
    position: "absolute" as "absolute",
    opacity: transition >= 3 ? 1 : 0,
    config: { duration: 300 },
  });

  const propsSecondMsg = useSpring({
    zIndex: transition >= 5 ? 10 : -1,
    width: "38.8em",
    left: 0,
    top: transition > 3 ? "calc(50vh + 13.88vh)" : "calc(50vh + 18vh)",
    position: "absolute" as "absolute",
    opacity: transition >= 5 ? 1 : 0,
    config: { duration: 600 },
  });

  const propsThirdMsg = useSpring({
    zIndex: transition >= 6 ? 10 : -1,
    width: "38.8em",
    left: 0,
    top: "calc(50vh + 29.38vh)",
    position: "absolute" as "absolute",
    opacity: transition >= 6 ? 1 : 0,
    config: { duration: 1200 },
  });

  const propsFourMsg = useSpring({
    zIndex: transition >= 7 ? 10 : -1,
    width: "38.8em",
    left: 0,
    top: "calc(50vh + 46vh)",
    position: "absolute" as "absolute",
    opacity: transition >= 7 ? 1 : 0,
    config: { duration: 1200 },
  });

  return (
    <>
      {transition <= 4 && <Backdrop show={transition > 0} duration={600} from={"#000000"} to={"transparent"} />}
      {transition > 4 && <Backdrop show={transition === 8} duration={600} from={"transparent"} to={"#000000"} />}
      <Backdrop show={transition > 0} duration={600} />

      <Box style={{ ...styles.container }}>
        <animated.div style={{ ...styles.containerBox, ...propsOpacityContainer }}>
          <animated.div style={{ ...propsCharacterWorker }}>
            <img src={characterWorker} alt="" style={{ width: "100%" }} />
          </animated.div>
          <animated.div style={{ ...propsCharacterPassenger }}>
            <img src={characterPassenger} alt="" style={{ width: "100%" }} />
          </animated.div>
          <animated.div style={{ ...styles.chatBox, ...propsChatBox }}>
            <animated.div style={{ ...propsFirstMsg }}>
              <Message
                name={"Counter staff"}
                message={
                  "Welcome back Mrs Elena. Looks like you have one seat upgrade to business class. We were able to seat you close to the passenger. Hope you have a pleasant flight!"
                }
              />
            </animated.div>

            <animated.div style={{ ...propsSecondMsg }}>
              <Message align="right" name={"Elena"} message={"That is really helpful"} />
            </animated.div>
            <animated.div style={{ ...propsThirdMsg }}>
              <Message name={"Counter staff"} message={"Please proceed to security. Your gate details are on your boarding pass."} />
            </animated.div>
            <animated.div style={{ ...propsFourMsg }}>
              <Message align="right" name={"Elena"} message={"Thank you!"} />
            </animated.div>
          </animated.div>
        </animated.div>
      </Box>
    </>
  );
};

const styles = {
  container: {
    overflow: "hidden !important",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  containerBox: {
    position: "relative" as "relative",
    width: "38.8em",
    height: "100vh",
    display: "flex",
    zIndex: 0,
  },
  chatBox: {
    position: "relative" as "relative",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as "column",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.story.stage,
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(StageFour, mapStateToProps, mapDispatchToProps);
