import { Box, Button, Stack } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LinkIcon from "@mui/icons-material/Link";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

import * as appActions from "../../Actions/appActions";

interface GenericFinishedStoryProps {
  character: CharacterType;
  t: (key: string) => ReactNode;
  setScreenPopUp: (value: ScreenPopUpType | null) => void;
  setStory: (value: Partial<StoryType>) => void;
  setStage: (stage: number) => void;
  setTransition: (stage: number, transition: number) => void;
}

const links = {
  officer: {
    website: "",
    video: "https://videos.infosys.com/watch/sdswX3pEnfvVhTfYybS3yj",
    whitepaper: "https://www.infosys.com/industries/travel-hospitality/insights/documents/watch-tower.pdf",
  },
  agent: {
    website: "https://www.infosys.com/industries/travel-hospitality/industry-offerings/unit-load-device-management.html",
    video: "https://videos.infosys.com/watch/XHdZH76Bx4wrFneavbWobW",
    whitepaper: "",
  },
  runner: {
    website: "https://www.infosys.com/industries/travel-hospitality/industry-offerings/bag-runner-dispatcher.html",
    video: "https://videos.infosys.com/watch/cmVnktESfJ4hM4GoRpZqW4",
    whitepaper: "",
  },
  passenger: {
    website: "https://www.infosys.com/industries/travel-hospitality/industry-offerings/infosys-genome-solution.html",
    video: "https://videos.infosys.com/watch/RrptyB3T1zSdRABx7MSZVR",
    whitepaper: "",
  },
};

const GenericFinishedStory = (props: GenericFinishedStoryProps) => {
  const { t, character, setScreenPopUp, setStory, setStage, setTransition } = props;

  const handleExit = () => {
    setStage(3);
    setStory({ character: null, stage: 1, transition: 0, screen: 0 });
    setTransition(3, 2);
    setScreenPopUp(null);
  };

  return (
    <Box sx={styles.screen}>
      <Box sx={styles.container}>
        <Box style={styles.title}>{t("end_screens.title")}</Box>
        <Box style={styles.subtitle_1}>{t(`end_screens.${character}.subtitle_1`)}</Box>
        <Box style={styles.subtitle_2}>{t(`end_screens.${character}.subtitle_2`)}</Box>
        <Box style={styles.text}>{t(`end_screens.${character}.text`)}</Box>
        <Stack direction={"row"} sx={{ marginTop: "4.16666667em" }}>
          {links[character].video && (
            <Box sx={styles.containerBtn}>
              <Button target={"blank"} href={links[character].video} variant="contained" endIcon={<SlideshowIcon />}>
                Video
              </Button>
            </Box>
          )}
          {links[character].website && (
            <Box sx={styles.containerBtn}>
              <Button target={"blank"} href={links[character].website} variant="contained" endIcon={<LinkIcon />}>
                Website
              </Button>
            </Box>
          )}
          {links[character].whitepaper && (
            <Box sx={styles.containerBtn}>
              <Button target={"blank"} href={links[character].whitepaper} variant="contained" endIcon={<TextSnippetIcon />}>
                View Whitepaper
              </Button>
            </Box>
          )}
          <Box sx={styles.containerBtn}>
            <Button onClick={handleExit} variant="contained" endIcon={<ArrowForwardIcon />}>
              Exit
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

const styles = {
  screen: {
    position: "absolute",
    backgroundColor: "#000000E0",
    zIndex: 1000,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "44.6875em",
  },
  title: {
    fontSize: "4.375em",
    textAlign: "center" as "center",
    fontFamily: "Tungsten",
    textTransform: "uppercase" as "uppercase",
    height: "0.7739em",
    lineHeight: "0.7739em",
    letterSpacing: "2px",
  },
  subtitle_1: {
    marginTop: "1.1112em",
    fontSize: "1.875em",
    textAlign: "center" as "center",
    fontWeight: "bold",
  },
  subtitle_2: {
    marginTop: "0.25em",
    color: "#00B28F",
    fontSize: "1.25em",
  },
  text: {
    marginTop: "2em",
    fontSize: "1.04166667em",
    textAlign: "center" as "center",
  },
  containerBtn: {
    marginRight: "1em",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    character: state.appReducer.story.character,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setScreenPopUp: appActions.setScreenPopUp,
  setStory: appActions.setStory,
  setStage: appActions.setStage,
  setTransition: appActions.setTransition,
};

export default connectWithDispatch(GenericFinishedStory, mapStateToProps, mapDispatchToProps);
