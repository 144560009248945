import * as Types from "../Constants/Types";

export const setIsLoading = (value: boolean) => ({ type: Types.SET_IS_LOADING, value });

export const setStage = (value: number) => ({ type: Types.SET_STAGE, value });

export const setTransition = (stage: number, transition: number) => ({
  type: Types.SET_TRANSITION,
  value: { stage, transition },
});

export const setStory = (value: Partial<StoryType>) => ({ type: Types.SET_STORY, value });

export const setScreenPopUp = (value: ScreenPopUpType) => ({ type: Types.SET_SCREEN_POPUP, value });
