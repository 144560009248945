import GenericScreen from "../GenericScreen";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { Box } from "@mui/material";

import imgScreen from "../../../../../../Assets/StoryRunner/BRD10.png";
import ticket from "../../../../../../Assets/StoryRunner/Group_1261152859.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const Screen = (props: ScreenProps) => {
  const { transition, setStory, screen } = props;

  const handleNextScreen = () => {
    setTimeout(() => setStory({ screen: screen + 1 }), 1000);
  };

  return (
    <>
      <GenericScreen
        showComponent={transition >= 4}
        showContent={true}
        icon={<CropFreeIcon sx={styles.icon} />}
        title={"Scan Bags"}
        text={"Scan the bag to check if they match the task at hand."}
        panelRight={<PhoneScreenOne handleNextScreen={handleNextScreen} />}
        srcImg={ticket}
      />
    </>
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
    borderRadius: "26px",
  },
};

interface PhoneScreenOneProps {
  handleNextScreen: () => void;
}

const PhoneScreenOne = (props: PhoneScreenOneProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        onClick={props.handleNextScreen}
        sx={{ cursor: "pointer", position: "absolute", bottom: "16vh", width: "15.1vh", height: "5vh", left: "13vh" }}
      ></Box>
      <img style={styles.imgScreen} src={imgScreen} alt="" />
    </Box>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
