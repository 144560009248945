import GenericScreen from "../GenericScreen";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button } from "@mui/material";

import imgScreen from "../../../../../../Assets/StoryRunner/Group_1261152847.png";

import connectWithDispatch from "../../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../../Actions/appActions";

interface ScreenProps {
  transition: number;
  setScreenPopUp: (value: ScreenPopUpType | null) => void;
  t: (key: string) => ReactNode;
}

const Screen = (props: ScreenProps) => {
  const { transition, setScreenPopUp } = props;

  const handleExit = () => setScreenPopUp("storyEnded");

  return (
    <>
      <GenericScreen
        showComponent={transition >= 4}
        showContent={true}
        icon={<StarBorderIcon sx={styles.icon} />}
        title={"Great work John"}
        text={"Let's get on to the next task"}
        extra={
          <Box sx={{ marginTop: "1.25em" }}>
            <Button onClick={handleExit} variant="contained" endIcon={<ArrowForwardIcon />}>
              Exit
            </Button>
          </Box>
        }
        panelRight={<PhoneScreenOne />}
      />
    </>
  );
};

const styles = {
  icon: {
    fontSize: "5.2083em",
    color: "#00B28F",
  },
  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
    borderRadius: "26px",
  },
  btn: {},
};

const PhoneScreenOne = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <img style={styles.imgScreen} src={imgScreen} alt="" />
    </Box>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setScreenPopUp: appActions.setScreenPopUp,
};

export default connectWithDispatch(Screen, mapStateToProps, mapDispatchToProps);
