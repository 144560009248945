import React from "react";
import { useTransition, animated } from "@react-spring/web";

interface ScreenAnimatorProps {
  currentScreen: number;
  screens: ReactNode[];
  width?: string;
}

const ScreenAnimator = (props: ScreenAnimatorProps) => {
  const { currentScreen, screens, width = "auto" } = props;

  const transitions = useTransition(currentScreen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
  });

  return (
    <div style={{ position: "relative", height: "89.63vh", width }}>
      {transitions((style: any, screenIndex: number) => (
        <animated.div
          key={screenIndex}
          style={{
            ...style,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: screenIndex === currentScreen ? 1 : 0,
            pointerEvents: screenIndex === currentScreen ? "auto" : "none",
          }}
        >
          {screens[screenIndex]}
        </animated.div>
      ))}
    </div>
  );
};

export default ScreenAnimator;
