import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";

import connectWithDispatch from "../../Hooks/connectWithDispatch";

import * as appActions from "../../Actions/appActions";

import useImagePreloader from "../../Hooks/useImagePreloader";
import videoIntro from "../../Assets/Stages/INTRO.mp4";
import videoAgent from "../../Assets/StoryAgent/GHAFull.mp4";
import videoOfficer from "../../Assets/StoryOfficer/CTFull.mp4";
import videoRunner from "../../Assets/StoryRunner/BRFull.mp4";
import videoPassenger from "../../Assets/StoryPassenger/PassengerFull.mp4";

import IntroLastFrame from "../../Assets/Stages/bkg-characterselection.jpg";
import bg_GHA from "../../Assets/StoryAgent/bkg-gha.jpg";
import bg_CT from "../../Assets/StoryOfficer/bkg-controltower.jpg";
import bg_BH from "../../Assets/StoryRunner/bkg-bagrunner.jpg";
import bg_PA from "../../Assets/StoryPassenger/bkg-passenger.jpg";

interface BackgroundProps {
  stage: number;
  transition: number;
  story: StoryType;
  setStage: (stage: number) => void;
  setStory: (value: Partial<StoryType>) => void;
}

const mediaItems: MediaType[] = [
  {
    type: "video",
    src: videoIntro,
  },
  {
    type: "video",
    src: videoAgent,
  },
  {
    type: "video",
    src: videoOfficer,
  },
  {
    type: "video",
    src: videoRunner,
  },
  {
    type: "video",
    src: videoPassenger,
  },
];

const Background = (props: BackgroundProps) => {
  const { stage, transition, story, setStage, setStory } = props;
  const [bg, setBg] = useState(`url(${IntroLastFrame})`);
  const { imagesPreloaded } = useImagePreloader(mediaItems);
  const videoIntroRef = useRef<HTMLVideoElement>(null);
  const videoAgentRef = useRef<HTMLVideoElement>(null);
  const videoOfficerRef = useRef<HTMLVideoElement>(null);
  const videoRunnerRef = useRef<HTMLVideoElement>(null);
  const videoPassengerRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const checkBg = async () => {
      if (stage === 1 || stage === 2 || (stage === 3 && transition < 3)) {
        setBg(`url(${IntroLastFrame}) center center /cover`);
      } else {
        switch (story.character) {
          case "agent":
            if (stage === 3 && transition === 2 && story.transition >= 0) setBg(`url(${IntroLastFrame}) center center /cover`);
            setTimeout(() => {
              if (stage === 3 && transition >= 3 && story.transition >= 0) setBg(`url(${bg_GHA}) center center /cover`);
            }, 200);

            break;
          case "officer":
            if (stage === 3 && transition === 2 && story.transition >= 0) setBg(`url(${IntroLastFrame}) center center /cover`);
            setTimeout(() => {
              if (stage === 3 && transition >= 3 && story.transition >= 0) setBg(`url(${bg_CT}) center center /cover`);
            }, 200);
            break;
          case "runner":
            if (stage === 3 && transition === 2 && story.transition >= 0) setBg(`url(${IntroLastFrame}) center center /cover`);
            setTimeout(() => {
              if (stage === 3 && transition >= 3 && story.transition >= 0) setBg(`url(${bg_BH}) center center /cover`);
            }, 200);
            break;
          case "passenger":
            if (stage === 3 && transition === 2 && story.transition >= 0) setBg(`url(${IntroLastFrame}) center center /cover`);
            setTimeout(() => {
              if (stage === 3 && transition >= 3 && story.transition >= 0) setBg(`url(${bg_PA}) center center /cover`);
            }, 200);
            break;
          default:
            setBg(`none`);
            break;
        }
      }
    };

    checkBg();
    return () => {};
  }, [stage, transition, story]);

  useEffect(() => {
    if (stage === 1 && transition === 3) videoIntroRef.current?.play();
    return () => {};
  }, [stage, transition, videoIntroRef]);

  useEffect(() => {
    if (stage === 3 && story.character === "agent" && story.transition === 0) videoAgentRef.current?.play();
    if (stage === 3 && story.character === "officer" && story.transition === 0) videoOfficerRef.current?.play();
    if (stage === 3 && story.character === "runner" && story.transition === 0) videoRunnerRef.current?.play();
    if (stage === 3 && story.character === "passenger" && story.transition === 0) videoPassengerRef.current?.play();

    return () => {};
  }, [stage, story, videoAgentRef, videoOfficerRef]);

  const handleOnEnded = () => setTimeout(() => setStage(stage + 1), 100);

  const handleOnEndedVideo = () => {
    if (story.transition === 0) setStory({ transition: story.transition + 1 });
  };

  const propsVideoAgent = useSpring({
    opacity: story.character === "agent" && story.stage === 1 && story.transition === 0 ? 1 : 0,
    config: { duration: 100 },
  });

  const propsVideoOfficer = useSpring({
    opacity: story.character === "officer" && story.stage === 1 && story.transition === 0 ? 1 : 0,
    config: { duration: 100 },
  });

  const propsVideoRunner = useSpring({
    opacity: story.character === "runner" && story.stage === 1 && story.transition === 0 ? 1 : 0,
    config: { duration: 100 },
  });

  const propsVideoPassenger = useSpring({
    opacity: story.character === "passenger" && story.stage === 1 && story.transition === 0 ? 1 : 0,
    config: { duration: 100 },
  });

  return (
    <>
      {imagesPreloaded && stage === 1 && (
        <Box sx={{ ...styles.containerVideo, zIndex: stage < 3 ? -1 : -5 }}>
          <video
            ref={videoIntroRef}
            controls={false}
            style={styles.video}
            onEnded={handleOnEnded}
            src={videoIntro}
            autoPlay={false}
          ></video>
        </Box>
      )}
      {imagesPreloaded && stage === 3 && (
        <animated.div style={{ ...styles.containerVideo, zIndex: stage < 3 ? -1 : -5, ...propsVideoAgent }}>
          <video
            ref={videoAgentRef}
            controls={false}
            style={styles.video}
            onEnded={handleOnEndedVideo}
            src={videoAgent}
            autoPlay={false}
          ></video>
        </animated.div>
      )}
      {imagesPreloaded && stage === 3 && (
        <animated.div style={{ ...styles.containerVideo, zIndex: stage < 3 ? -1 : -5, ...propsVideoOfficer }}>
          <video
            ref={videoOfficerRef}
            controls={false}
            style={styles.video}
            onEnded={handleOnEndedVideo}
            src={videoOfficer}
            autoPlay={false}
          ></video>
        </animated.div>
      )}
      {imagesPreloaded && stage === 3 && (
        <animated.div style={{ ...styles.containerVideo, zIndex: stage < 3 ? -1 : -5, ...propsVideoRunner }}>
          <video
            ref={videoRunnerRef}
            controls={false}
            style={styles.video}
            onEnded={handleOnEndedVideo}
            src={videoRunner}
            autoPlay={false}
          ></video>
        </animated.div>
      )}
      {imagesPreloaded && stage === 3 && (
        <animated.div style={{ ...styles.containerVideo, zIndex: stage < 3 ? -1 : -5, ...propsVideoPassenger }}>
          <video
            ref={videoPassengerRef}
            controls={false}
            style={styles.video}
            onEnded={handleOnEndedVideo}
            src={videoPassenger}
            autoPlay={false}
          ></video>
        </animated.div>
      )}
      <Box sx={{ ...styles.bg, background: bg }}></Box>
    </>
  );
};

const styles = {
  containerVideo: {
    position: "absolute" as "absolute",
    width: "100%",
    top: 0,
    // paddingLeft: "146px",
    height: "100vh",
    overflow: "hidden",
  },
  bg: {
    transition: "background 0.8s ease",
    width: "100%",
    height: "100VH",
    backgroundSize: "cover",
    position: "absolute" as "absolute",
    zIndex: -10,
  },
  video: {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover" as "cover",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    stage: state.appReducer.stage,
    transition: state.appReducer.transition,
    story: state.appReducer.story,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStage: appActions.setStage,
  setStory: appActions.setStory,
};

export default connectWithDispatch(Background, mapStateToProps, mapDispatchToProps);
