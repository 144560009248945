import { useSpring, animated } from "@react-spring/web";

interface HandleBgProps {
  showBg: boolean;
  bg: string;
}

const HandleBg = (props: HandleBgProps) => {
  const { showBg, bg } = props;
  const changeBg = useSpring({ opacity: showBg ? 1 : 0, config: { duration: 500 } });

  return (
    <animated.div
      style={{
        ...changeBg,
        ...styles.bg,
        background: `url(${bg})  center center/cover`,
      }}
    ></animated.div>
  );
};

const styles = {
  bg: {
    zIndex: -1,
    position: "absolute" as "absolute",
    width: "100%",
    height: "100vh",
    left: 0,
    top: 0,
  },
};

export default HandleBg;
