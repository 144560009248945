import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import bg from "../../../../../Assets/StoryRunner/phone.png";

import connectWithDispatch from "../../../../../Hooks/connectWithDispatch";

import * as appActions from "../../../../../Actions/appActions";

import Screen1 from "./phone/Screen1";
import Screen2 from "./phone/Screen2";
import Screen3 from "./phone/Screen3";
import Screen4 from "./phone/Screen4";
import Screen5 from "./phone/Screen5";
import Screen6 from "./phone/Screen6";
import Screen7 from "./phone/Screen7";
import Screen9 from "./phone/Screen9";
import Screen10 from "./phone/Screen10";
import Screen11 from "./phone/Screen11";
import Screen13 from "./phone/Screen13";
import Screen14 from "./phone/Screen14";
import Screen15 from "./phone/Screen15";
import Screen16 from "./phone/Screen16";
import Screen17 from "./phone/Screen17";
import Screen18 from "./phone/Screen18";
import Screen19 from "./phone/Screen19";
import Screen20 from "./phone/Screen20";
import Screen21 from "./phone/Screen21";

import ScreenAnimator from "../../../../Commons/ScreenAnimator";

interface StageProps {
  transition: number;
  screen: number;
  setStory: (value: Partial<StoryType>) => void;
  t: (key: string) => ReactNode;
}

const StageThree = (props: StageProps) => {
  const { transition, setStory, screen } = props;

  const animacion = useSpring(
    transition >= 2
      ? {
          from: {
            transform: "translateY(120%) rotate(-30deg)",
          },
          to: {
            transform: "translateY(0%) rotate(0deg)",
          },
          config: {
            duration: 500,
            tension: 300,
            friction: 20,
          },
        }
      : {
          transform: "translateY(120%) rotate(-30deg)",
        }
  );

  useEffect(() => {
    if (transition === 1 && screen === 0) setTimeout(() => setStory({ transition: transition + 1, screen: 1 }), 100);
    if (transition === 2) setTimeout(() => setStory({ transition: transition + 1 }), 1000);
    if (transition === 3) setTimeout(() => setStory({ transition: transition + 1 }), 1000);

    return () => {};
  }, [transition, screen, setStory]);

  useEffect(() => {
    if (screen === 8) setTimeout(() => setStory({ screen: screen + 1 }), 1500);
    if (screen === 11) setTimeout(() => setStory({ screen: screen + 1 }), 1500);
    if (screen === 12) setTimeout(() => setStory({ screen: screen + 1 }), 1500);
    if (screen === 16) setTimeout(() => setStory({ screen: screen + 1 }), 7000);
    return () => {};
  }, [screen, setStory]);

  const propsOpacity = useSpring({
    zIndex: screen === 8 || screen === 12 ? 100 : -1,
    background: "#000",
    position: "absolute" as "absolute",
    width: "100vw",
    height: "100vh",
    opacity: screen === 8 || screen === 12 ? 1 : 0,
    config: { duration: 500 },
  });

  return (
    <>
      <animated.div style={{ background: `url(${bg})`, ...styles.bgTablet, ...animacion }}>
        <ScreenAnimator
          currentScreen={screen - 1}
          width={"124.07vh"}
          screens={[
            <Screen1 />,
            <Screen2 />,
            <Screen3 />,
            <Screen4 />,
            <Screen5 />,
            <Screen6 />,
            <Screen7 />,
            <></>,
            <Screen9 />,
            <Screen10 />,
            <Screen11 />,
            <></>,
            <Screen13 />,
            <Screen14 />,
            <Screen15 />,
            <Screen16 />,
            <Screen17 />,
            <Screen18 />,
            <Screen19 />,
            <Screen20 />,
            <Screen21 />,
          ]}
        />
        <animated.div style={propsOpacity}></animated.div>
      </animated.div>
    </>
  );
};

const styles = {
  bgTablet: {
    backgroundSize: "contain",
    backgroundPosition: "center center",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
  },

  imgScreen: {
    objectFit: "contain" as "contain",
    width: "100%",
  },
};

const mapStateToProps = (state: StateType) => {
  return {
    transition: state.appReducer.story.transition,
    screen: state.appReducer.story.screen,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  setStory: appActions.setStory,
};

export default connectWithDispatch(StageThree, mapStateToProps, mapDispatchToProps);
